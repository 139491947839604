import { useState, useEffect } from "react";

declare const window: any;

// Convert UrlSearchParams to object
function paramsToObject(params: URLSearchParams) {
  const result: any = {};

  // @ts-ignore
  for (const key of params?.keys()) {
    result[key] = params.getAll(key).join(",");
  }

  return result;
}

const useUTMCapture = () => {
  const [utm, setUTMParams] = useState({});

  useEffect(() => {
    if (typeof window === "undefined") return;

    const url = new URL(window?.location);
    setUTMParams(paramsToObject(url.searchParams));
  }, []);

  return utm;
};

export default useUTMCapture;
