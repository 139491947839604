import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authenticatedAxios } from "../shared/setups/axios";

interface HospitalState {
  searchText: string | "";
  error: boolean;
  errorMsg: string | "";
  hospitalData: any;
  rowsPerPage: number | undefined;
  totalCount: number | undefined;
}

const initialState: HospitalState = {
  searchText: "",
  error: false,
  errorMsg: "",
  hospitalData: [],
  rowsPerPage: 12,
  totalCount: 0,
};

export const fetchNagebabaHospitalData = createAsyncThunk(
  "hospital/fetchNagebabaHospitalData",
  async (payload: any, { rejectWithValue }) => {
    try {
      // const { city } = payload;
      const finalPayloadValue = {
        hospitalName:
          payload.searchText === "" ? payload.city : payload.searchText,
        pageNo: payload?.pageNo,
        pageSize: payload.rowsPerPage,
      };

      return await authenticatedAxios
        .post("/api/v1/hospital/searchByCompany", finalPayloadValue)
        .then((response) => {
          if (!response?.data?.data.length) {
            return {
              error: true,
              errorMsg:
                "Sorry, we could not find any hospitals for this search.",
            };
          }

          return {
            error: false,
            searchText: payload.searchText,
            hospitalData: response?.data?.data,
            totalCount: response?.data?.totalCount,
          };
        });
    } catch (err: any) {
      return rejectWithValue(err?.response.data.errMsg);
    }
  }
);

export const fetchHospitalData = createAsyncThunk(
  "hospital/fetchHospitalData",
  async (payload: any, { rejectWithValue }) => {
    try {
      // const { city } = payload;
      const finalPayloadValue = {
        hospitalName:
          payload.searchText === "" ? payload.city : payload.searchText,
        pageNo: payload?.pageNo,
        pageSize: payload.rowsPerPage,
        userId: payload.userId,
        userPlanId: payload.userPlanId,
      };

      return await authenticatedAxios
        .post("/api/v1/hospital/search", finalPayloadValue)
        .then((response) => {
          if (!response?.data?.data.length) {
            return {
              error: true,
              errorMsg:
                "Sorry, we could not find any hospitals for this search.",
            };
          }

          return {
            error: false,
            searchText: payload.searchText,
            hospitalData: response?.data?.data,
            totalCount: response?.data?.totalCount,
          };
        });
    } catch (err: any) {
      return rejectWithValue(err?.response.data.errMsg);
    }
  }
);

const hospitalSlice = createSlice({
  name: "hospital",
  initialState,
  reducers: {
    clearHospitalData: () => initialState,
  },
  extraReducers: {
    [fetchHospitalData.fulfilled.toString()]: (
      state: HospitalState,
      { payload }
    ) => {
      console.log(payload);

      return { ...state, ...payload };
    },
    [fetchHospitalData.rejected.toString()]: (
      state: HospitalState,
      { payload }
    ) => {
      return { ...state, error: true, errorMsg: payload };
    },
    [fetchNagebabaHospitalData.fulfilled.toString()]: (
      state: HospitalState,
      { payload }
    ) => {
      console.log(payload);

      return { ...state, ...payload };
    },
    [fetchNagebabaHospitalData.rejected.toString()]: (
      state: HospitalState,
      { payload }
    ) => {
      return { ...state, error: true, errorMsg: payload };
    },
  },
});

export const { clearHospitalData } = hospitalSlice.actions;
export default hospitalSlice.reducer;
