// log the pageview with their URL
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    Moengage: any;
    dataLayer: any;
    gtag: any;
  }
}

export const pageview = (url: any) => {
  if (typeof window === "undefined") return;
  window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url,
  });
};

// log specific events happening.
export const event = ({ action, params }: any) => {
  if (typeof window === "undefined") return;

  console.log("Action", action, params);

  window?.gtag("event", action, params);
};

export const findClientId = (func: any) => {
  if (typeof window === "undefined") return;

  window.gtag(
    "get",
    process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID,
    "client_id",
    (client_id: any) => {
      func(client_id);
    }
  );
};
export const setUserId = ({ hubspotId }: any) => {
  // Skipping hubspot Id
  console.log("skip setting user_id on UA", hubspotId);
  // window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID, {
  //   user_id: hubspotId,
  // });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  pageView: pageview,
  event,
  findClientId,
  setUserId,
};
