import { useState } from "react";
// import { ClickAwayListener } from "@mui/material";

import { useMediaQuery } from "@mui/material";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import NavigationBar from "../Navigation/Bar";

import MinimalHeader from "../Header/MinimalHeader";
import MinimalFooter from "../Footer/MinimalFooter";

import LayoutWrapper from "../LayoutWrapper/LayoutWrapper";
import SectionWrapper from "./../SectionWrapper";

import footerData from "../data/footer.data.json";

export const LayoutHeaderLess = ({
  children,
  // headerProps,
  mainProps,
  footerProps,
  background,
  color,
}: any) => {
  // const [navState, setNavState] = useState(false);

  return (
    <>
      <LayoutWrapper
        header={<></>}
        footer={
          <SectionWrapper background="var(--kenkoDeepTeal)">
            <Footer data={footerData} />
          </SectionWrapper>
        }
        background={background}
        color={color}
        mainProps={mainProps}
        // headerProps={headerProps || { height: "80px" }}
        footerProps={footerProps}
        // navState={navState}
        navBar={<NavigationBar />}
      >
        {children}
      </LayoutWrapper>
    </>
  );
};

export const LayoutMinHeader = ({
  children,
  headerProps,
  mainProps,
  footerProps,
  background,
  color,
}: any) => {
  // const [navState, setNavState] = useState(false);

  return (
    <>
      <LayoutWrapper
        header={<MinimalHeader />}
        footer={
          <SectionWrapper background="var(--kenkoDeepTeal)">
            <Footer data={footerData} />
          </SectionWrapper>
        }
        background={background}
        color={color}
        mainProps={mainProps}
        headerProps={headerProps || { height: "80px" }}
        footerProps={footerProps}
        // navState={navState}
        navBar={<NavigationBar />}
      >
        {children}
      </LayoutWrapper>
    </>
  );
};

export const LayoutMinimal = ({
  children,
  showAuth,
  showFullFooter,
}: {
  children: any;
  showAuth?: boolean;
  showFullFooter?: boolean;
}) => {
  return (
    <LayoutWrapper
      background="var(--biege50)"
      header={<MinimalHeader showAuth={showAuth} />}
      headerProps={{
        background: "var(--biege50)",
        color: "var(--kenkoDeepTeal)",
      }}
      footer={
        showFullFooter ? (
          <SectionWrapper background="var(--kenkoDeepTeal)">
            <Footer data={footerData} showPlansSection={false} />
          </SectionWrapper>
        ) : (
          <MinimalFooter />
        )
      }
      footerProps={{
        bottom: "0px",
        background: "var(--biege50)",
      }}
    >
      {children}
    </LayoutWrapper>
  );
};

export const LayoutMinimalHeaderless = ({
  children,
  hideFooter,
}: {
  children: any;
  hideFooter: boolean;
}) => {
  return (
    <LayoutWrapper
      background="var(--biege50)"
      // header={<> </>}
      // headerProps={{
      //   background: "var(--biege50)",
      //   color: "var(--kenkoDeepTeal)",
      // }}
      footer={hideFooter ? null : <MinimalFooter />}
      footerProps={{
        position: "fixed",
        bottom: "0px",
        background: "var(--biege50)",
      }}
    >
      {children}
    </LayoutWrapper>
  );
};

const Layout = ({
  children,
  headerProps,
  mainProps,
  footerProps,
  background,
  color,
  parent,
  hideFooter,
}: any) => {
  const [navState, setNavState] = useState(false);

  // const theme = useTheme();
  const mobile = useMediaQuery((theme: any) => theme.breakpoints.down("600"));

  return (
    <>
      <LayoutWrapper
        header={
          parent !== "login-page" ? (
            <Header navState={navState} setNavState={setNavState} />
          ) : (
            ""
          )
        }
        footer={
          !hideFooter && (
            <SectionWrapper background="var(--kenkoDeepTeal)">
              <Footer data={footerData} />
            </SectionWrapper>
          )
        }
        background={background}
        color={color}
        mainProps={mainProps}
        headerProps={headerProps || { height: (mobile && "64px") || "80px" }}
        footerProps={footerProps}
        navState={navState}
        navBar={<NavigationBar closeNav={() => setNavState(false)} />}
        navProps={{
          width: "80vw",
          // borderRadius: "0 0 20px 20px",
        }}
      >
        {children}
      </LayoutWrapper>
    </>
  );
};

export default Layout;
