import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  authenticatedAxios,
  // authPaymentAxios,
} from "./../../shared/setups/axios";
import {
  pushAll,
  pushAllExceptMoengage,
} from "../../shared/utils/tracker.utils";
import { events } from "../../shared/constants";

export const getPlanBreakup: any = createAsyncThunk(
  "onboarding/getPlanBreakup",
  async (
    {
      groupId,
      dependents,
      couponCode,
      couponRemoved,
    }:
      | {
          groupId: string;
          dependents: {
            spouse: boolean;
            children: number;
          };
          couponCode?: string;
          couponRemoved?: boolean;
        }
      | any,
    { rejectWithValue }: any
  ) => {
    const payload = {
      dependents: {
        CHILD: dependents?.children,
        SPOUSE: dependents?.spouse ? 1 : 0,
      },
      couponCode,
      couponRemoved,
    };
    if (!groupId) {
      console.warn("getPlanBreakup(): groupId is required");
      return;
    }

    const res = await authenticatedAxios
      .post(`api/v2/plan-purchase/plan-groups/${groupId}`, payload)
      .catch((error: any) => {
        console.log("Onboarding Config Error", error);

        throw rejectWithValue(error?.data || error?.response || error);
      });

    return res?.data;
  }
);

export const getPaymentLink: any = createAsyncThunk(
  "onboarding/getPaymentLink",
  async (
    {
      basePlanId,
      // basePlanTenure,
      dependents,
      // paymentMode,
      basePlanType,
      couponCode,
      groupId,
    }:
      | {
          basePlanId: string;
          // basePlanTenure: string;
          dependents: {
            spouse: boolean;
            children: number;
          };
          // paymentMode: string;
          basePlanType: string;
          couponCode?: string;
          groupId: string;
        }
      | any,
    { rejectWithValue }: any
  ) => {
    const payload = {
      basePlanID: basePlanId,
      // basePlanTenure,
      dependents: {
        SPOUSE: dependents?.spouse ? 1 : 0,
        CHILD: dependents?.children,
      },
      // paymentMode,
      basePlanType,
      couponCode,
      groupId,
    };

    const res = await authenticatedAxios
      .post("api/v2/plan-purchase/purchase", payload)
      .catch((error: any) => {
        console.log("Onboarding Config Error", error);

        throw rejectWithValue(error?.data?.errMsg || error?.response || error);
      });

    return res?.data;
  }
);

export const getPaymentStatus = createAsyncThunk(
  "onboarding/getPaymentStatus",
  async (
    {
      orderId,
    }:
      | {
          orderId: string;
        }
      | any,
    { rejectWithValue }: any
  ) => {
    if (!orderId) return;

    const res = await authenticatedAxios
      .get(`api/v2/plan-purchase/getPaymentStatus/${orderId}`)
      .catch((error: any) => {
        console.log("Onboarding Config Error", error);

        throw rejectWithValue(error?.data || error?.response || error);
      });

    if (res?.data?.data?.code === 200) {
      if (
        res?.data?.data?.data?.platform == "android" ||
        res?.data?.data?.data?.platform == "ios"
      ) {
        pushAllExceptMoengage(
          `${events.PAYSTATUS_SUCCESS}_${res?.data?.data?.data?.platform}`
        );
      } else {
        pushAll(events.PAYSTATUS_SUCCESS);
      }
    }

    return res?.data;
  }
);
