import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";

import { authenticatedAxios } from "../shared/setups/axios";
import bankData from "./../shared/data/bank.data.json";

import { BANK_STATUS } from "../shared/constants/enums";

export const fetchBankDetails = createAsyncThunk(
  "bank/fetchDetails",
  async () => {
    const res = await authenticatedAxios.get(
      "https://kenko-ui-assets.s3.ap-south-1.amazonaws.com/bank-details.json"
    );
    // .catch((error: any) => {
    //   throw rejectWithValue(error?.data || error?.response?.data);
    // });

    if (!res?.data) return bankData?.topBanks;

    return res.data?.topBanks;
  }
);

export const verifyUserInfo = createAsyncThunk(
  "bank/verifyUserInfo",
  async ({ token }: any, { rejectWithValue }: any) => {
    if (!token) return;

    const res = await authenticatedAxios
      .get(`/api/v1/bankLink/validate/${token}`)
      .catch((err: any) => {
        const status = err?.data?.errMsg;
        const payload = { ...err?.data, status };

        throw rejectWithValue(payload);
      });

    const STATUSES = [
      BANK_STATUS.INCORRECT_URL,
      BANK_STATUS.ALREADY_COMPLETED,
      BANK_STATUS.EXPIRED,
    ];

    if (STATUSES.includes(res?.data?.data?.status)) {
      throw rejectWithValue(res?.data?.data);
    }

    return res?.data?.data;
  }
);

export const storeBankDetails = createAsyncThunk(
  "bank/storeDetails",
  async (
    {
      internalId,
      name,
      accountnumber,
      ifsc,
      bankname,
      pancardno,
      additionalProofDocuments,
    }: any,
    { rejectWithValue }: any
  ) => {
    const payload = {
      internalId: internalId,
      bankAccountName: name,
      bankAccountNumber: accountnumber,
      ifscCode: ifsc,
      bankName: bankname?.title ?? bankname,
      branchName: bankname?.title ?? bankname,
      panCard: pancardno,
      additionalProofDocuments,
    };

    await authenticatedAxios
      .post("/api/v1/bankDetails/public/create", payload)
      .catch((err: any) => {
        const payload = {
          submittedStatus: err?.data.errMsg,
          submitErr: true,
          respId: err?.data?.respId,
        };

        throw rejectWithValue(payload);
      });

    return {
      submittedStatus:
        "Thank you for your details. We're processing your request. Keep an eye out for an update.",
      submitErr: false,
    };
  }
);

export const verifyChequeLink = createAsyncThunk(
  "bank/verifyChequeLink",
  async ({ token }: any, { rejectWithValue }: any) => {
    if (!token) return;

    const res = await authenticatedAxios
      .get(`/api/v1/cheque/validate/${token}`)
      .catch((err: any) => {
        const status = err?.data?.errMsg;
        const payload = { ...err?.data, status };

        throw rejectWithValue(payload);
      });

    return res?.data?.data;
  }
);

export const uploadUserFiles = createAsyncThunk(
  "bank/uploadUserFiles",
  async ({ file, files, setFiles, user }: any, { rejectWithValue }: any) => {
    const payloadData = {
      userCollectionId: user,
      userDocType: "cancelled-cheque",
      fileType: "CANCELLED-CHEQUE",
    };

    const formData = new FormData();
    formData.append("request", JSON.stringify(payloadData));
    formData.append("file", file);

    if (files?.length < 6) {
      const res = await authenticatedAxios
        .post("/api/v1/user/file/uploadFile", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((err: any) => {
          console.log("Rejected Due to", err);

          throw rejectWithValue(err);
        });

      const resultData = res.data.data;
      resultData.preview = file.preview;
      setFiles([...files, resultData]);

      return [...files, resultData];
    }
  }
);

export const submitUploadedFiles = createAsyncThunk(
  "bank/submitUploadedFiles",
  async ({ token, filesData, setFiles }: any, { rejectWithValue }: any) => {
    const res = await authenticatedAxios
      .post(`/api/v1/cheque/submit/${token}`, {
        chequeImages: filesData,
      })
      .catch((err: any) => {
        throw rejectWithValue({ ...err?.data });
      });

    setFiles([]);

    return res?.data;
  }
);

export const uploadDocuments = createAsyncThunk(
  "bank/uploadDocuments",
  async ({ formData }: any, { rejectWithValue }) => {
    const res: any = await authenticatedAxios
      .post("/api/v1/user/file/uploadFile", formData, {
        headers: {
          Accept: "application/json",
        },
      })
      .catch((err: any) => {
        console.log("Rejected Due to", err);

        return rejectWithValue(err);
      });

    return res?.data?.data;
  }
);

interface IBankState {
  internalId: string;
  status: string;
  initialLoading: boolean;
  apiInProgress: boolean;
  isValidationFailed: boolean;
  submitErr: boolean;
  submittedStatus: string;
  dispMsg: string;
  bankName: string;
  bankData: any;
  filesData: any;
  rejectedFiles: any;
  respId: string;
  bankDocuments: any;
}

const initialState: IBankState = {
  internalId: "",
  status: "",
  initialLoading: true,
  apiInProgress: false,
  isValidationFailed: false,
  submitErr: false,
  submittedStatus: "",
  dispMsg: "",
  bankName: "",
  bankData: [],
  filesData: [],
  rejectedFiles: [],
  respId: "",
  bankDocuments: [],
};

const bankDetailsSlice = createSlice({
  name: "bankDetails",
  initialState,
  reducers: {
    clearUserPlans() {
      return initialState;
    },

    storeBankUserInfo: (state: IBankState, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
        initialLoading: false,
      };
    },

    setIncorrectValidation: (state: IBankState, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
        initialLoading: false,
        isValidationFailed: true,
      };
    },

    setSubmittedResult: (state: IBankState, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },

    setBankDetails: (state: IBankState, action: PayloadAction<any>) => {
      return {
        ...state,
        bankData: [...action.payload],
      };
    },

    setBankName: (state: IBankState, action: PayloadAction<any>) => {
      return {
        ...state,
        bankName: action.payload,
      };
    },

    setFilesData: (state: IBankState, action: PayloadAction<any>) => {
      return {
        ...state,
        filesData: action?.payload,
      };
    },

    addFilesData: (state: IBankState, action: PayloadAction<any>) => {
      return {
        ...state,
        filesData: [...state.filesData, action?.payload],
      };
    },

    removeFile: (state: IBankState, action: PayloadAction<any>) => {
      return {
        ...state,
        filesData: state?.filesData?.filter(
          (file: any) => file?.preview !== action?.payload?.preview
        ),
      };
    },
  },

  extraReducers: (builder: any) => {
    // Fetch User Details
    builder.addCase(
      fetchBankDetails.fulfilled,
      (state: IBankState, action: PayloadAction<any>) => {
        return {
          ...state,
          bankData: action?.payload,
          initialLoading: false,
        };
      }
    );

    builder.addCase(fetchBankDetails.rejected, (state: IBankState) => {
      return {
        ...state,
        initialLoading: false,
      };
    });

    // Verify User Info
    builder.addCase(
      verifyUserInfo.fulfilled,
      (state: IBankState, action: PayloadAction<any>) => {
        return {
          ...state,
          ...action.payload,
          initialLoading: false,
        };
      }
    );

    builder.addCase(
      verifyUserInfo.rejected,
      (state: IBankState, action: PayloadAction<any>) => {
        return {
          ...state,
          ...action.payload,
          initialLoading: false,
          isValidationFailed: true,
        };
      }
    );

    // Store Bank Details

    builder.addCase(
      storeBankDetails.rejected,
      (state: IBankState, action: PayloadAction<any>) => {
        return { ...state, ...action.payload };
      }
    );

    builder.addCase(
      storeBankDetails.fulfilled,
      (state: IBankState, action: PayloadAction<any>) => {
        return { ...state, ...action.payload };
      }
    );

    // Validate Cheque Link
    builder.addCase(
      verifyChequeLink.rejected,
      (state: IBankState, action: PayloadAction<any>) => {
        return {
          ...state,
          ...action.payload,
          initialLoading: false,
          isValidationFailed: true,
        };
      }
    );

    builder.addCase(
      verifyChequeLink.fulfilled,
      (state: IBankState, action: PayloadAction<any>) => {
        return {
          ...state,
          ...action.payload,
          initialLoading: false,
        };
      }
    );

    // Upload User Files
    builder.addCase(
      uploadUserFiles.rejected,
      (state: IBankState, action: PayloadAction<any>) => {
        return {
          ...state,
          ...action.payload,
        };
      }
    );

    builder.addCase(
      uploadUserFiles.fulfilled,
      (state: IBankState, action: PayloadAction<any>) => {
        return {
          ...state,
          filesData: action?.payload,
        };
      }
    );

    // Submit Uploaded Files

    builder.addCase(
      submitUploadedFiles.rejected,
      (state: IBankState, action: PayloadAction<any>) => {
        console.log("Error data", action?.payload);

        return {
          ...state,
          ...action.payload,
          submitErr: true,
          submittedStatus: action?.payload?.errMsg,
        };
      }
    );

    builder.addCase(
      submitUploadedFiles.fulfilled,
      (state: IBankState, action: PayloadAction<any>) => {
        return {
          ...state,
          ...action.payload,
          submitErr: false,
          submittedStatus:
            "Thank you for your details. We're processing your request. Keep an eye out for an update.",
        };
      }
    );

    builder.addCase(
      uploadDocuments.fulfilled,
      (state: IBankState, action: PayloadAction<any>) => {
        let newDocs;

        if (current(state.bankDocuments).length > 0) {
          const foundDuplicate = current(state).bankDocuments.find(
            (doc: any) => doc?.userDocType === action.payload.userDocType
          );

          newDocs = foundDuplicate
            ? current(state).bankDocuments.map((prev: any) =>
                prev.userDocType === action.payload.userDocType
                  ? action.payload
                  : prev
              )
            : [...current(state).bankDocuments, action.payload];
        } else {
          newDocs = [action.payload];
        }

        return {
          ...state,
          bankDocuments: newDocs,
        };
      }
    );

    builder.addCase(
      uploadDocuments.rejected,
      (state: IBankState, action: PayloadAction<any>) => {
        return {
          ...state,
          ...action.payload,
          submitErr: true,
          submittedStatus: action?.payload?.errMsg,
        };
      }
    );
  },
});

export const { setFilesData } = bankDetailsSlice.actions;

export default bankDetailsSlice.reducer;
