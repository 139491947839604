import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";

import { routes } from "../constants";
import { logoutUser, autoLogin } from "./../../slices/auth-slice";
import { setPlatformType } from "../../slices/onboarding.slice";

const useAutoLogin = ({
  redirect,
  cleanup,
}: {
  redirect?: boolean;
  cleanup?: Function;
} = {}) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state: any) => state.auth);
  const [lock, setLock] = useState(true);

  useEffect(() => {
    const { emailId, token, platform } = router?.query;

    if (!emailId || !token) {
      return;
    }

    dispatch(logoutUser());
    if (cleanup) {
      cleanup();
    }

    dispatch(autoLogin({ email: emailId, password: token, platform }));
    setLock(false);

    if (platform) {
      // @ts-ignore
      dispatch(setPlatformType(platform));
    }
  }, [router?.query]);

  useEffect(() => {
    if (!redirect || lock) return;

    if (!userInfo.userId) {
      router.push(routes.LOGIN_PAGE);
    }
  }, [lock]);
};

export default useAutoLogin;
