import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authPaymentAxios } from "./../shared/setups/axios";

import GA from "./../shared/utils/ga.utils";
import Moengage from "./../shared/utils/moengage.utils";

export const createPaymentLink = createAsyncThunk(
  "payment/createLink",
  async (
    {
      userId,
      user,
      plan,
      planDuration,
      couponCodeId,
      annualFlag,
      doubleCoverageFlag,
    }: any,
    { rejectWithValue }: any
  ) => {
    const apiPayload = {
      userCollectionId: userId,
      transactionDetails: {
        transactionEntity: "PLAN",
        userPlanDetails: {
          basePlanCollectionId: plan._id,
          doubleCoverageFlag: doubleCoverageFlag,
          annualFlag: annualFlag,
          planDuration,
          couponCodeId: couponCodeId || "",
        },
      },
      versionSource: "FHC",
      transactionMedium: "RAZORPAY",
      razorPaymentLinkDetails: {
        amountInRupees: plan?.basePlanPrice,
        currency: "INR",
        reference_id: "P" + Date.now() + "-" + user?._id,

        description: `Payment for ${user?.name} ${user?.emailId}`,
        customer: {
          name: user?.name,
          contact: user?.phoneNo,
          email: user?.emailId,
        },
        notify: {},
        reminder_enable: true,
        notes: {},
        callback_url: `https://${window.location.host}/payment?userId=${user?._id}&planId=${plan?._id}`,
        callback_method: "get",
      },
    };

    const res = await authPaymentAxios
      .post("/api/v2/payment/createPaymentLink", apiPayload)
      .catch((error) => {
        console.log("Payment Link Error", error);

        throw rejectWithValue(error?.data || error?.response || error);
      });

    GA.event({
      action: "purchase_initiated",
      params: {
        event_category: "Website Purchase",
        event_label: "Purchase Initiated",
        currency: "INR",
        transaction_id: res.data.data.razorpayReferenceId,
        value: res.data.data.userPlan,
        items: [
          {
            item_id: plan._id,
            price: res.data.data.userPlan,
          },
        ],
      },
    });

    Moengage.pushEvent("web_purchase_initiated", {
      currency: "INR",
      transaction_id: res.data.data.razorpayReferenceId,
      value: res.data.data.userPlan,
      item_id: plan._id,
      price: res.data.data.userPlan,
      planName: res.data.data.userPlan,
    });

    return res?.data;
  }
);

export const verifyPaymentStatus = createAsyncThunk(
  "payment/verifyStatus",
  async (
    { pendingUserPlanId, razorPayLink, basePlanName, userId }: any,
    { rejectWithValue }: any
  ) => {
    const apiPayload = {
      isAndroid: false,
      razorPayLink: razorPayLink || "",
      pendingUserPlanId: pendingUserPlanId || "",
      userId: userId || "",
    };

    console.log("API PAYLOAD", apiPayload, basePlanName);

    // ?isAndroid=${isAndroid}&paymentLinkID=${ razorPayLink ? razorPayLink : ""}&pendingUserPlanId=${pendingUserPlanId}&userId=${userId}

    const params = new URLSearchParams(apiPayload as any).toString();

    const res = await authPaymentAxios
      .get(`/api/v2/payment/verifyPayment?${params}`)
      .catch((error) => {
        console.log("Payment Link Error", error);

        throw rejectWithValue(error?.data || error?.response || error);
      });

    if (res.data.data.code === 200) {
      GA.event({
        action: "purchase_successful",
        params: {
          event_category: "Website Purchase",
          event_label: "Purchase Successful",
          currency: "INR",
          transaction_id: res.data.data.razorpayReferenceId,
          value: res.data.data.userPlan,

          items: [
            {
              item_id: pendingUserPlanId,
              price: res.data.data.userPlan,
            },
          ],
        },
      });

      Moengage.pushEvent("web_purchase_successful", {
        currency: "INR",
        transaction_id: res.data.data.razorpayReferenceId,
        value: res.data.data.userPlan,
        item_id: pendingUserPlanId,
        price: res.data.data.userPlan,
        planName: res.data.data.userPlan,
      });
    }

    return res?.data;
  }
);

interface IPaymentState {
  isLoading: boolean;
  isError: boolean;
  error: string;
  paymentInitiated: boolean;
  payment: any;
  paymentStatus: {
    isLoading: boolean;
    isError: boolean;
    error: string;
    data: any;
  };
}

const initialState: IPaymentState = {
  isLoading: false,
  isError: false,
  error: "",
  paymentInitiated: false,
  payment: {},
  paymentStatus: {
    isLoading: false,
    isError: false,
    error: "",
    data: {},
  },
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    clearPayment() {
      return initialState;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(createPaymentLink.pending, () => {
      return {
        ...initialState,
        isLoading: true,
      };
    });

    builder.addCase(createPaymentLink.fulfilled, (state: any, action: any) => {
      const { data } = action?.payload || {};

      return {
        ...state,
        isLoading: false,
        paymentInitiated: true,
        payment: {
          ...data,
        },
      };
    });

    builder.addCase(createPaymentLink.rejected, (state: any, action: any) => {
      const { code, respId, errMsg: error } = action.payload || {};

      return {
        ...initialState,
        code: code || "",
        respId: respId || "",
        isLoading: false,
        isError: true,
        error: error.errMsg || error,
      };
    });

    // Verify Payment Status APIs
    builder.addCase(verifyPaymentStatus.pending, (state: any) => {
      return {
        ...state,
        paymentStatus: {
          ...initialState.paymentStatus,
          isLoading: true,
        },
      };
    });

    builder.addCase(
      verifyPaymentStatus.fulfilled,
      (state: any, action: any) => {
        const { code, respId, data } = action?.payload || {};

        return {
          ...state,
          paymentStatus: {
            isLoading: false,
            code: code || "",
            respId: respId || "",
            data: data,
          },
        };
      }
    );

    builder.addCase(verifyPaymentStatus.rejected, (state: any, action: any) => {
      const { code, respId, errMsg: error } = action.payload || {};

      return {
        ...state,
        paymentStatus: {
          ...initialState?.paymentStatus,
          isLoading: false,
          isError: true,
          code: code || "",
          respId: respId || "",
          error: error?.errMsg || error,
        },
      };
    });
  },
});

export const { clearPayment } = paymentSlice.actions;

export default paymentSlice.reducer;
