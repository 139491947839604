import React from "react";
import styled from "@emotion/styled";

import { debounce } from "../../utils/ui.utils";

const ButtonWrapper = styled.span`
  /* Default */
  .btn {
    padding: 14px 19px;
    display: flex;
    align-items: center;
    gap: 2px;
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
    border: none;
    cursor: pointer;
    font-family: Poppins;
    box-shadow: var(--btnBoxShadow);
  }

  .btn .btn-startIcon,
  .btn .btn-endIcon {
    transform: scale(0.8);
  }

  .btn:hover {
    opacity: 0.7;
  }

  .btn:active {
    box-shadow: var(--btnActiveShadow);
  }

  /* Variants */
  .btn-contained,
  .btn-contained.btn-primary {
    background-color: var(--kenkoGolden);
    color: var(--kenkoDeepTeal);
  }

  .btn-contained.btn-secondary {
    background-color: var(--kenkoDeepTeal);
    color: var(--kenkoPureWhite);
  }

  .btn-contained.btn-success {
    background-color: var(--kenkoAvocado);
    color: var(--kenkoPureWhite);
  }

  .btn-contained.btn-accent {
    background-color: var(--kenkoOrange);
    color: var(--kenkoPureWhite);
  }

  .btn-outlined {
    border: 1px solid var(--kenkoDeepTeal);
    color: var(--kenkoDeepTeal);
    background-color: var(--kenkoPureWhite);
  }

  .btn-outlined.btn-primary {
    border: 1px solid var(--kenkoGolden);
    background-color: var(--kenkoPureWhite);
    color: var(--kenkoGolden);
  }

  .btn-outlined.btn-success {
    border: 1px solid var(--kenkoAvocado);
    color: var(--kenkoAvocado);
  }

  .btn-outlined.btn-accent {
    border: 1px solid var(--kenkoOrange);
    color: var(--kenkoOrange);
  }

  .btn-text {
    line-height: 21px;
    background-color: unset;
    box-shadow: unset;
  }

  .btn-outline {
    border: 1px solid var(--kenkoDeepTeal);
  }

  .btn-text:hover {
    opacity: 0.5;
    text-decoration: underline;
  }

  .btn-text,
  .btn-text.btn-primary {
    color: var(--kenkoGolden);
  }

  .btn-text.btn-success {
    color: var(--kenkoAvocado);
  }

  .btn-text.btn-accent {
    color: var(--kenkoOrange);
  }

  /* Disabled */
  .btn-disabled {
    background-color: var(--gray300);
    color: var(--gray100);
    cursor: default;
  }

  .btn-disabled.btn-text {
    background-color: unset;
    color: unset;
    opacity: 0.3;
  }

  .btn-disabled.btn-text:hover {
    opacity: 0.3;
    text-decoration: unset;
  }

  .btn-disabled:hover {
    opacity: unset;
  }

  .btn-disabled:active {
    box-shadow: unset;
  }

  /* Size */

  .btn-xs {
    border-radius: 4px;
    padding: 8px 20px;
  }

  .btn-sm {
    padding: 8px 11px;
    font-size: 12px;
    border-radius: 6px;
  }

  .btn-md {
    padding: 9px 12px;
    font-size: 14px;
    border-radius: 8px;
  }

  .btn-lg {
    padding: 11px 15px;
    font-size: 16px;
    border-radius: 10px;
  }

  .btn-xl {
    padding: 14px 19px;
    font-size: 20px;
    border-radius: 12px;
  }
`;

export default function Button({
  label,
  disabled,
  size, // sm, md, lg, xl
  variant, // contained, outlined
  color,
  onClick,
  startIcon,
  endIcon,
  style,
  className,
  wrapperClass,
  id,
  type,
  debounceDuration,
}: any) {
  const onClickFn = debounceDuration
    ? debounce(onClick, debounceDuration)
    : onClick;

  return (
    <ButtonWrapper className={"wrapper-class " + wrapperClass}>
      <button
        className={[
          "btn",
          `btn-${size}`,
          `btn-${variant}`,
          !disabled && color && `btn-${color}`,
          disabled && "btn-disabled",
          className,
        ].join(" ")}
        onClick={onClickFn}
        disabled={disabled}
        style={{ ...style, color: color }}
        type={type}
        id={id}
      >
        {startIcon && <span className="btn-startIcon">{startIcon}</span>}
        {label}
        {endIcon && <span className="btn-endIcon">{endIcon}</span>}
      </button>
    </ButtonWrapper>
  );
}

Button.defaultProps = {
  label: "Button",
  size: "md",
  variant: "contained",
  disabled: false,
  onClick: undefined,
};
