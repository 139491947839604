export const useGlobalDispatch = (
  frontEndName: string,
  reducerName: string,
  onStorageChange?: any
) => {
  return ({ type, payload }: { type: string; payload: any }) => {
    const key = `persist:${frontEndName}`;
    const state = localStorage.getItem(`persist:${frontEndName}`) || "{}";
    const data = JSON.parse(state);

    if (!type || !payload)
      throw new Error(
        "Property type & payload must exist on the global dispatch action"
      );

    if (type === "CLEAR") {
      localStorage.setItem(
        key,
        JSON.stringify({
          ...data,
          [reducerName]: JSON.stringify({}),
        })
      );

      if (onStorageChange) onStorageChange({ key });

      return;
    }

    if (type === "REPLACE") {
      localStorage.setItem(
        key,
        JSON.stringify({
          ...data,
          [reducerName]: JSON.stringify(payload),
        })
      );

      if (onStorageChange) onStorageChange({ key });

      return;
    }

    const reducerData: any = data?.[reducerName];

    if (type === "UPDATE") {
      const newData = JSON.stringify({
        ...data,
        [reducerName]: {
          ...reducerData,
          ...payload,
        },
      });

      localStorage.setItem(key, newData);
      onStorageChange({ key });
    }
  };
};

export const globalDispatch = useGlobalDispatch;

export default useGlobalDispatch;
