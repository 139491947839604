import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

import storage from "redux-persist/lib/storage";
import rootReducer from "./slices";
import { useDispatch } from "react-redux";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "utmReducer", "tracker", "onboarding"],
};

const isClient = typeof window !== "undefined";
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    isClient
      ? getDefaultMiddleware({
          serializableCheck: false,
        }).concat([
          createStateSyncMiddleware({
            blacklist: ["persist/PERSIST"],
          }),
        ])
      : getDefaultMiddleware({
          serializableCheck: false,
        }),
  devTools:
    typeof window !== "undefined" && window.location.host !== "kenkohealth.in",
});

initMessageListener(store);

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
