import { createSlice } from "@reduxjs/toolkit";
// createSlice : API function to define logic , PayloadAction: Represent content of a single Action

interface ITrackerState {
  signup: boolean;
  pixelFired: boolean;
  isReferral: boolean;
}

const initialState: ITrackerState = {
  signup: false,
  pixelFired: false,
  isReferral: false,
};

const trackerSlice = createSlice({
  name: "tracker",
  initialState,
  reducers: {
    signupSuccess(state) {
      return {
        ...state,
        signup: true,
      };
    },

    setPixelFired(state, { payload }) {
      return {
        ...state,
        pixelFired: payload,
      };
    },

    setReferral(state, { payload }) {
      return {
        ...state,
        isReferral: payload,
      };
    },

    clearTracker() {
      return initialState;
    },
  },
});

export const { signupSuccess, clearTracker, setPixelFired, setReferral } =
  trackerSlice.actions;

export default trackerSlice.reducer;
