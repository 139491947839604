import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import dynamic from "next/dynamic";

import { useRouter } from "./../../../hooks";
import { useMediaQuery } from "@mui/material";
import KenkoLogo from "../../../icons/KenkoLogo";

import { InfoIcon } from "../../../icons";

import Wrapper from "../Wrapper/Wrapper";
import WrapperItem from "../WrapperItem/WrapperItem";

import { useTranslation } from "next-i18next";
import { navLinks } from "./header.trans";

import { appleGreen200, addOpacity } from "../../../styles/colors";

import tracker from "../../../utils/tracker.utils";
import { events } from "../../../constants";

const HamburgerMenu = dynamic(() =>
  import("../../../icons/HamburgerMenu").then((mod) => mod.HamburgerMenu)
);
const MobileCallBack = dynamic(() => import("../Callback/MobileCallback"));
const AuthButtons = dynamic(() => import("./Blocks/AuthButtons"));

const HeaderWrapper = styled.div`
  .button-signup {
    padding: 8px 20px;
    border-radius: 4px;
    width: 100%;
  }

  .button-login {
    font-weight: 500;
    cursor: pointer;
    padding: 8px 20px;
    border-radius: 4px;
  }

  .button-emergency {
    height: 35px;
    box-shadow: none;
    padding: 10px 20px !important;
  }

  .disclaimer {
    background: var(--kenkoDeepTeal);
    color: var(--biege50);

    font-size: 12px;
    font-weight: 400;

    position: fixed;
    width: 100%;
    z-index: 1000;

    display: flex;
    justify-content: center;

    .disclaimer-content {
      padding: 10px 5vw;

      display: flex;
      align-items: center;
      // limit behavior revert
      gap: 10px;

      width: 100%;
      max-width: 1536px;
    }
  }

  .header {
    background: var(--kenkoPureWhite);
    color: var(--kenkoDeepTeal);
    box-shadow: 0px 1px 10px rgba(123, 123, 123, 0.12),
      0px 2px 4px rgba(123, 123, 123, 0.2);

    position: fixed;
    top: 37px;
    z-index: 1000;
    width: 100%;

    height: 94px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 5vw;

    font-size: 16px;
    font-weight: 600;
    color: var(--kenkoDeepTeal);

    @media (max-width: 1200px) {
      top: 0;
    }
    @media (max-width: 600px) {
      top: 0;
      height: 80px;
    }
  }

  .section-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;

    @media (min-width: 1732px) {
      padding: 0 5vw;
    }
    @media (min-width: 1537px) {
      max-width: 1536px;
      margin: 0 auto;
    }
  }

  .nav-menu-icon {
    display: none;
  }

  .logo-wrapper {
    gap: 5vw;

    .logo {
      cursor: pointer;
    }
  }

  .web-navlinks {
    font-family: Figtree;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  .button-bar {
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 14px;

    width: 100%;
    font-family: Poppins;
    padding: 10px 20px;

    span {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .all-plans-button {
      width: 100%;
      padding: 12px;
      display: flex;
      justify-content: center;
    }
  }

  .plans-popup {
    padding: 20px;
    min-width: 600px;
    max-width: 600px;
    background: var(--kenkoPureWhite);
    border-radius: 12px;

    position: absolute;
    top: 100%;
    left: 0;

    display: grid;
    grid-template-columns: 50% 50%;
    flex-wrap: wrap;

    .popup-links {
      font-weight: 600;
      padding: 7px 20px;
      margin-block: 5px;

      flex: 0.5;
      width: 100%;

      display: flex;
      align-items: center;
      gap: 10px;
      border-radius: 8px;

      min-height: 45px;

      span {
        display: flex;
        align-items: center;
      }

      &:hover {
        background: ${addOpacity(appleGreen200)("5f")};
      }
    }
  }

  @media (min-width: 768px) {
    .logo-wrapper {
      .logo {
        margin-top: 4px;
      }
    }
  }

  @media (max-width: 768px) {
    .webonly {
      display: none !important;
    }

    .header {
      height: 64px;
    }

    .nav-menu-icon {
      display: block;
    }

    .menu-icon {
      height: 44px;
      width: 44px;
      all: unset;
      cursor: pointer;
    }

    .logo-wrapper {
      gap: calc(50% - 12px);
      flex: 1;
    }

    .web-navlinks,
    .web-emergency,
    .web-buttons,
    .web-already-member {
      display: none;
    }
  }

  @media (max-width: 600px) {
    .logo-wrapper {
      gap: calc(50% - 80px);
      flex: 1;
    }
  }
`;

export const GetACallBack = () => {
  const { userInfo } = useSelector((state: any) => state.auth);

  const userCurrentPlans = userInfo?.user?.userCurrentPlans;

  const [disableCallback, setDisableCallback] = useState(false);

  const checkDisableCallback = () => {
    const disableValues = userCurrentPlans?.map(
      (plan: any) => plan?.basePlan?.basePlanRules?.disableSupportPhoneCall
    );

    return disableValues?.some((item: boolean) => item === true) ? true : false;
  };

  useEffect(() => {
    if (userCurrentPlans?.length > 0) {
      setDisableCallback(checkDisableCallback());
    }
  }, [userCurrentPlans]);

  return disableCallback ? null : (
    <div>
      <MobileCallBack />
    </div>
  );
};

const Header = ({ navState, setNavState, disableClicks, hideLinks }: any) => {
  const { t } = useTranslation("header");
  const router = useRouter();
  const [lock, setLock] = useState(false);

  const mobile = useMediaQuery((theme: any) => theme.breakpoints.up(600));

  return (
    <HeaderWrapper>
      <div className="disclaimer  webonly">
        <div className="disclaimer-content">
          <InfoIcon />
          <span>{t(navLinks?.disclaimer)}</span>
        </div>
      </div>

      <div className="header">
        <div className="section-wrapper">
          <Wrapper align="center" className="logo-wrapper">
            <WrapperItem
              justifySelf="center"
              onClick={() => !disableClicks && router.push("/")}
              className="logo"
            >
              <KenkoLogo height={26} width={109} />
            </WrapperItem>

            {!hideLinks && (
              <Wrapper className="web-navlinks" align="center" spacing="50px">
                <a href={navLinks?.section1?.link}>
                  <span
                    onClick={() => {
                      tracker.pushWeb(events?.HEADER_PLANS, {
                        Page_Url: window.location.href,
                      });
                    }}
                  >
                    {t(navLinks?.section1?.heading)}
                  </span>
                </a>

                <Wrapper align="center">
                  <a href={navLinks?.section2?.link}>
                    <span
                      onClick={() => {
                        tracker.pushWeb(events?.HEADER_HOSPITAL, {
                          Page_Url: window.location.href,
                        });
                      }}
                    >
                      {t(navLinks?.section2?.heading)}
                    </span>
                  </a>
                </Wrapper>

                <Wrapper align="center">
                  <a href={navLinks?.section3?.link}>
                    <span
                      onClick={() => {
                        tracker.pushWeb(events?.HEADER_BLOG, {
                          Page_Url: window.location.href,
                        });
                      }}
                    >
                      {t(navLinks?.section3?.heading)}
                    </span>
                  </a>
                </Wrapper>
              </Wrapper>
            )}
          </Wrapper>

          <Wrapper justify="right" align="center" spacing="20px">
            {mobile && <GetACallBack />}

            <AuthButtons popupLeft="0" disableClicks={disableClicks} />
          </Wrapper>

          <WrapperItem className="nav-menu-icon">
            <button
              onClick={() => {
                // Click Away Listener Prevention
                if (!lock) setNavState(!navState);
                setLock(!lock);
              }}
              className="menu-icon"
              aria-label="Menu Button"
            >
              <HamburgerMenu />
            </button>
          </WrapperItem>
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default Header;
