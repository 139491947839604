import { IconProps } from "../types";

const InfoIcon = ({ width, height, color }: IconProps) => {
  return (
    <>
      <svg
        width={width || 16}
        height={height || 17}
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 12.2382C7.56 12.2382 7.2 11.8746 7.2 11.4302L7.2 8.19851C7.2 7.75414 7.56 7.39057 8 7.39057C8.44 7.39057 8.8 7.75414 8.8 8.19851L8.8 11.4302C8.8 11.8746 8.44 12.2382 8 12.2382ZM8.008 16.2778C12.424 16.2778 16 12.6583 16 8.19851C16 3.73872 12.424 0.11918 8.008 0.11918C3.584 0.11918 1.08544e-06 3.73872 6.99382e-07 8.1985C3.13323e-07 12.6583 3.584 16.2778 8.008 16.2778ZM8 1.73505C11.536 1.73505 14.4 4.62744 14.4 8.19851C14.4 11.7696 11.536 14.662 8 14.662C4.464 14.662 1.6 11.7696 1.6 8.19851C1.6 4.62744 4.464 1.73505 8 1.73505ZM7.2 4.96678C7.2 4.52495 7.55817 4.15884 8 4.15884C8.44183 4.15884 8.8 4.52495 8.8 4.96678C8.8 5.4086 8.44183 5.77471 8 5.77471C7.55817 5.77471 7.2 5.4086 7.2 4.96678Z"
          fill={color || "#FBFBF6"}
        />
      </svg>
    </>
  );
};

export default InfoIcon;
