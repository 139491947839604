const key = "redux.events";

export const getEvents = () => {
  const events = localStorage.getItem(key);
  return events ? JSON.parse(events) : [];
};

export const pushEvent = (data: { type: string; payload: any }) => {
  const events = getEvents();
  events.push(data);

  localStorage.setItem(key, JSON.stringify(events));
};

export const popEvent = () => {
  const events = getEvents();
  const event = events.shift();

  localStorage.setItem(key, JSON.stringify(events || []));

  return event;
};

export const clearEvents = () => {
  localStorage.setItem(key, JSON.stringify([]));
};

const dispatcher = {
  getEvents,
  pushEvent,
  popEvent,
  clearEvents,
};

export default dispatcher;
