export const HOME_PAGE = "/";
export const SIGNUP_PAGE = "/kenko-score/my-details";
export const LOGIN_PAGE = "/login";
export const HERO_SIGNUP_PAGE = "/signup";
export const PLANS_PAGE = "/plans";
export const MY_PLANS_PAGE = "/myplans";
export const KENKO_SCORE_PAGE = "/kenko-score";
export const QUIZ_START_PAGE = "/kenko-score/start";
export const START_PAGE = "/kenko-score/start";
export const QUIZ_PAGE = "/kenko-score/basic-information";
export const CHOOSE_PLAN_PAGE = "/choose-plan";
export const CART_PAGE = "/cart";
export const SCORE_METER_PAGE = "/kenko-score/meter";
export const KENKO_END_PAGE = "/kenko-score/end";
export const BOOK_SLOT_PAGE = "/kenko-score/book-slot";
export const LOGIN_MOBILE_PAGE = "/login/mobile";

export const PURCHASE_PATH = "/purchase-path";
export const PURCHASE_PATH_DEPENDENTS = "/purchase-path/dependents";
export const PURCHASE_PATH_QUIZ = "/purchase-path/quiz";
export const PURCHASE_PATH_PAYMENTS = "/purchase-path/payment";
export const PURCHASE_PATH_CHECKOUT = "/purchase-path/checkout";
export const PURCHASE_PATH_EXCLUSIONS = "/purchase-path/exclusions";
export const PURCHASE_PATH_DOWNLOAD_APP = "/purchase-path/download-app";
export const PURCHASE_PATH_WAITING_PERIOD = "/purchase-path/waiting-period";

export const FOREVER_PLAN = "/Forever-plan";
export const EVERYDAY_PLAN = "/Everyday-plan";
export const CARE_PLAN = "/Care-plan";

export const PLAN_SELECTION = "/plan-selection";
export const COMPARE_PLANS = "/compare-plans";

export const REFERRAL_MARKETING = "/referrals";
export const REFERRAL_DASHBOARD = "/referral-details";
export const ORDER_SERVICE_PRODUCT_DASHBOARD_SEARCH_DOCTOR_CONSULTATION =
  "order-service/product/search/dashboard/DOCTOR_CONSULTATION";
export const ORDER_SERVICE_PRODUCT_CATEGORIES_DOCTOR_CONSULTATION =
  "/order-service/product/categories/DOCTOR_CONSULTATION";
export const ORDER_SERVICE_PRODUCT_ADD_DOCTOR =
  "order-service/product/DOCTOR_CONSULTATION";
export const ORDER_SERVICE_PRODUCT_ADD_CLINIC =
  "order-service/product/DOCTOR_CONSULTATION/clinic";

export const ORDER_SERVICE_GET_DOCTOR_AND_CLINIC_DETAILS =
  "/order-service/product/productDetails/dashboard/DOCTOR_CONSULTATION";

export const ORDER_SERVICE_EDIT_CLINIC =
  "/order-service/product/DOCTOR_CONSULTATION";

export const ORDER_SERVICE_PRODUCT_EDIT_DOCTOR =
  "/order-service/product/DOCTOR_CONSULTATION";
