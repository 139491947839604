import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  fetchIpAddress,
  preSignup,
  verifySignupPayload,
  verifyOtp,
  fetchUserDetails,
  updateLead,
} from "./auth/signup";
import {
  generateOtp,
  resendOTP,
  verifyOtpAndLogin,
  emailLogin,
  autoLogin,
  getUserPlan,
  getOnboarding,
} from "./auth/login";
import { resetPassword, verifyforgotPasswordOTP } from "./auth/forgotPassword";
import { OTP_VERIFIED } from "../shared/constants/enums";

export interface IAuth {
  userInfo: any;
  paymentVerification: any;
  isError: boolean;
  errMsg: null | string;
  isSignUpError: boolean;

  onBoardingInfo: any;
  onBoarding: {
    isLoading: boolean;
    isError: boolean;
  };
  signUpErrorMsg: null | string;
  isSignUpVerifyError: boolean;
  signUpVerifyErrorMsg: null | string;
  userSignUpData: any;
  redirectTo: string | null;
  apiFetchInProgress: boolean;
  signUpOtpSent: boolean;
  preSignUpData: {
    type: string;
    responseForValidation: any;
    userDetails: any | null;
    feedBackMsg: string;
  };
  forgotPasswordState: {
    userID: null | string;
    isOtpVerified: boolean;
    augmentedHash: null | string;
    otpVerifyError: null | string;
    otpSendError: null | string;
    otpSentSucessfully: boolean;
    passwordChangeSuccess: boolean;
    passwordChangeError: null | string;
    otpResendSuccess: null | string;
    otpResendFailed: null | string;
  };
  currentPlan: string;
  isReEnter: boolean;
  isServiceable: boolean;
  paymentCode: null | string;
  showLoginDialog: boolean;

  //  pre otp reducer
  preOtpVerificationData: any;

  //  tracker reducer
  signupSuccess: boolean;
  signupStatus: null | string;
  isPincodeServiceable: null | boolean | string;

  // hubspot
  hubspotId: null | string;
  ipAddress: string;

  // warning note
  signup: boolean;
  quiz: boolean;
  redirect: boolean;

  // plans
  userPlans: any;

  isLoading: boolean;
  sendOTP: any;
  phoneLogin: any;
  emailLogin: any;
  forgotPassword: any;
  resetPassword: any;

  autoLogin: any;
}

const initialState: IAuth = {
  userInfo: null,
  paymentVerification: null,
  isLoading: false,
  isError: false,
  errMsg: null,

  // on boarding data
  onBoardingInfo: null,
  onBoarding: {
    isLoading: false,
    isError: true,
  },

  isSignUpError: false,
  signUpErrorMsg: null,
  isSignUpVerifyError: true,
  signUpVerifyErrorMsg: null,
  userSignUpData: null,
  redirectTo: null,
  apiFetchInProgress: false,
  signUpOtpSent: false,

  preSignUpData: {
    type: "EXPERIAN",
    responseForValidation: {},
    userDetails: null,
    feedBackMsg: "Experian Validation In Progress",
  },

  forgotPasswordState: {
    userID: null,
    isOtpVerified: false,
    augmentedHash: null,
    otpVerifyError: null,
    otpSendError: null,
    otpSentSucessfully: false,
    passwordChangeSuccess: false,
    passwordChangeError: null,
    otpResendSuccess: null,
    otpResendFailed: null,
  },
  currentPlan: "",
  isReEnter: false,
  isServiceable: false,
  paymentCode: null,
  showLoginDialog: false,

  //  pre otp reducer
  preOtpVerificationData: null,

  //  tracker reducer
  signupSuccess: false,
  signupStatus: "",
  isPincodeServiceable: null,

  // hubspot
  hubspotId: null,
  ipAddress: "",

  // warning note
  signup: false,
  quiz: false,
  redirect: false,

  sendOTP: {
    isLoading: false,
    isError: false,
    error: "",
    code: 0,
    respId: "",
    otpSent: false,
    phone: "",
    augmentedHash: "",
    type: "",
    status: "",
  },

  phoneLogin: {
    isLoading: false,
    isError: false,
    error: "",
    code: 0,
    respId: "",
    success: false,
  },

  emailLogin: {
    isLoading: false,
    isError: false,
    error: "",
    code: 0,
    respId: "",
    success: false,
  },

  forgotPassword: {
    isLoading: false,
    isError: false,
    error: "",
    code: 0,
    respId: "",
    otpVerfied: false,
    userId: "",
  },

  resetPassword: {
    isLoading: false,
    isError: false,
    error: "",
    code: 0,
    respId: "",
    success: false,
  },

  autoLogin: {
    isLoading: false,
    isError: false,
    error: "",
    code: 0,
    respId: "",
    success: false,
  },

  userPlans: {},
};

const authSlice: any = createSlice({
  name: "auth",
  initialState,

  reducers: {
    setTrackerState: (state, { payload }) => {
      state.signupStatus = payload;
    },

    clearSignUpError: (state) => {
      return {
        ...state,
        isSignUpVerfifyError: false,
        signUpVerifyErrorMsg: "",
        signUpErrorMsg: "",
        isSignUpError: false,
      };
    },

    logoutUser: () => {
      return initialState;
    },

    clearAuth: () => {
      return initialState;
    },

    clearEmailLogin: (state: any) => {
      return {
        ...state,
        emailLogin: {
          ...initialState.emailLogin,
        },
      };
    },
    signUpFailed: (state, { payload }) => {
      return {
        ...state,
        isSignUpError: true,
        signUpErrorMsg: payload,
      };
    },

    clickOnOtpPage: (state) => {
      return {
        ...state,
        signup: true,
        quiz: false,
      };
    },

    clickOnQuizPage: (state) => {
      return {
        ...state,
        quiz: true,
      };
    },

    setTokens: (state: any, { payload }: PayloadAction<any>) => {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          accessToken: payload.accessToken,
          refreshToken: payload.refreshToken,
        },
      };
    },

    setAuth: (state: any, action: PayloadAction<any>) => {
      return {
        ...initialState,
        ...(action?.payload || {}),
      };
    },

    setPlan: (state: any, action: PayloadAction<any>) => {
      return {
        ...state,
        currentPlan: action.payload,
      };
    },

    clearPlan: (state: any) => {
      return {
        ...state,
        currentPlan: "",
      };
    },

    setShowLogin: (state: IAuth, action: PayloadAction<any>) => {
      return {
        ...state,
        showLoginDialog: action.payload,
      };
    },

    clearSendOTP: (state: any) => {
      return {
        ...state,
        sendOTP: { ...initialState.sendOTP },
      };
    },

    clearResendOTP: (state: any) => {
      return {
        ...state,
        sendOTP: {
          ...state.sendOTP,
          isResending: false,
          resent: false,
        },
      };
    },

    clearRedirect: (state: any) => {
      return {
        ...state,
        redirect: false,
      };
    },
  },

  extraReducers: {
    [fetchIpAddress.fulfilled.toString()]: (
      state: any,
      { payload }: { payload: any }
    ) => {
      return { ...state, ipAddress: payload };
    },

    // pre signup
    [preSignup.fulfilled.toString()]: (
      state: any,
      { payload }: { payload: any }
    ) => {
      return { ...state, hubspotId: payload };
    },

    [preSignup.rejected.toString()]: (state: any) => {
      return { ...state, isSignUpVerifyError: true };
    },

    // verify signup payload
    [verifySignupPayload.pending.toString()]: (state: any) => {
      return { ...state, isLoading: true, isSignUpVerifyError: true };
    },

    [verifySignupPayload.fulfilled.toString()]: (
      state: any,
      { payload }: { payload: any }
    ) => {
      return {
        ...state,
        isLoading: false,
        preSignUpData: {
          userDetails: payload.userDetails,
          type: payload.type,
          feedBackMsg: payload.feedBackMsg,
          responseForValidation: payload.responseForValidation,
        },
        isSignUpVerifyError: false,
      };
    },

    [verifySignupPayload.rejected.toString()]: (
      state: any,
      { payload }: { payload: any }
    ) => {
      return {
        ...state,
        isLoading: false,
        isSignUpVerifyError: true,
        signUpVerifyErrorMsg: payload,
      };
    },

    // verify otp
    [verifyOtp.fulfilled.toString()]: (
      state: any,
      { payload }: { payload: any }
    ) => {
      return {
        ...state,
        isLoading: false,
        isSignUpError: false,
        userInfo: { ...payload.userInfo },
      };
    },

    [verifyOtp.rejected.toString()]: (
      state: any,
      { payload }: { payload: any }
    ) => {
      return {
        ...state,
        isLoading: false,
        isSignUpError: true,
        signUpErrorMsg: payload,
      };
    },

    [getUserPlan.fulfilled.toString()]: (
      state: any,
      { payload }: { payload: any }
    ) => {
      return {
        ...state,
        userPlans: payload,
      };
    },

    [fetchUserDetails.pending.toString()]: (
      state: any,
      { payload }: { payload: any }
    ) => {
      console.log("payload", payload);

      return {
        ...state,
        isLoading: true,
        isError: false,
        userInfo: {
          ...state?.userInfo,
          user: {
            ...payload?.data,
          },
        },
      };
    },

    [fetchUserDetails.fulfilled.toString()]: (
      state: any,
      { payload }: { payload: any }
    ) => {
      console.log("payload", payload);

      return {
        ...state,
        isLoading: false,
        userInfo: {
          ...state?.userInfo,
          user: {
            ...payload?.data,
          },
        },
      };
    },

    [fetchUserDetails.rejected.toString()]: (
      state: any,
      { payload }: { payload: any }
    ) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: "Error while fetching user details",
        userInfo: {
          ...state?.userInfo,
          user: {
            ...payload?.data,
          },
        },
      };
    },

    //  Send Login OTP
    [generateOtp.pending.toString()]: (state: any) => {
      return { ...state, sendOTP: { ...initialState.sendOTP } };
    },

    [generateOtp.fulfilled.toString()]: (state: any, action: any) => {
      return {
        ...state,
        sendOTP: {
          ...initialState.sendOTP,
          isLoading: false,
          otpSent: true,
          augmentedHash: action?.payload?.data?.augmentedHash,
          phone: action?.payload?.data?.phoneNo,
          type: action?.payload?.type,
        },
      };
    },

    [generateOtp.rejected.toString()]: (state: any, action: any) => {
      return {
        ...state,
        sendOTP: {
          isLoading: false,
          isError: true,
          respId: action?.payload?.respId,
          code: action?.payload?.code,
          error: action?.payload?.errMsg,
        },
      };
    },

    // Verify OTP and Login
    [verifyOtpAndLogin.pending.toString()]: (state: any) => {
      return {
        ...state,
        sendOTP: { ...state.sendOTP },
        phoneLogin: {
          ...state.phoneLogin,
          isLoading: true,
        },
      };
    },

    [verifyOtpAndLogin.fulfilled.toString()]: (state: any, action: any) => {
      return {
        ...state,
        userInfo: action?.payload?.data,
        // Check after integrating new api
        // redirect: true,
        sendOTP: {
          ...state.sendOTP,
          otpSent: false,
          status: OTP_VERIFIED,
        },
        phoneLogin: {
          ...state.phoneLogin,
          isLoading: false,
          isError: false,
          error: false,
        },
      };
    },

    [verifyOtpAndLogin.rejected.toString()]: (state: any) => {
      return {
        ...state,
        phoneLogin: {
          isLoading: false,
          isError: true,
        },
      };
    },

    // Onboarding data
    [getOnboarding.pending.toString()]: (state: any) => {
      return {
        ...state,
        onBoarding: {
          isLoading: false,
          isError: true,
        },
      };
    },

    [getOnboarding.fulfilled.toString()]: (state: any, action: any) => {
      console.log("getOnboarding", action?.payload, state);
      return {
        ...state,
        onBoardingInfo: action?.payload?.data,
      };
    },

    [getOnboarding.rejected.toString()]: (state: any) => {
      return {
        ...state,
        onBoarding: {
          isLoading: false,
          isError: true,
        },
      };
    },

    // Update lead => signup
    [updateLead.pending.toString()]: (state: any) => {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    },

    [updateLead.fulfilled.toString()]: (state: any, action: any) => {
      console.log(action?.payload?.data, "xxx");
      return {
        ...state,
        isLoading: false,
        isError: false,
        userInfo: { ...state.userInfo, ...action?.payload?.data },
      };
    },

    [updateLead.rejected.toString()]: (state: any, action: any) => {
      return {
        ...state,
        isSignUpError: true,
        signUpErrorMsg: action?.payload?.errMsg,
      };
    },

    // Email Login
    [emailLogin.pending.toString()]: (state: any) => {
      return {
        ...state,
        emailLogin: {
          ...initialState.emailLogin,
        },
      };
    },

    [emailLogin.fulfilled.toString()]: (state: any, action: any) => {
      return {
        ...state,
        redirect: true,
        userInfo: action?.payload?.data,
        emailLogin: {
          isLoading: false,
          isError: false,
          error: false,
        },
      };
    },

    [emailLogin.rejected.toString()]: (state: any, action: any) => {
      return {
        ...state,
        emailLogin: {
          isLoading: false,
          isError: true,
          respId: action?.payload?.respId,
          code: action?.payload?.code,
          error: action?.payload?.errMsg,
        },
      };
    },

    // Verify Forgot Password OTP
    [verifyforgotPasswordOTP.pending.toString()]: (state: any) => {
      return {
        ...state,
        forgotPassword: {
          ...initialState.forgotPassword,
        },
      };
    },

    [verifyforgotPasswordOTP.fulfilled.toString()]: (
      state: any,
      action: any
    ) => {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          accessToken: action?.payload?.data?.accessToken,
          refreshToken: action?.payload?.data.refreshToken,
        },
        sendOTP: {
          ...initialState.sendOTP,
          otpSent: false,
        },
        forgotPassword: {
          ...initialState.forgotPassword,
          otpVerified: true,
          userId: action?.payload?.data?.userId,
        },
      };
    },

    [verifyforgotPasswordOTP.rejected.toString()]: (
      state: any,
      action: any
    ) => {
      return {
        ...state,
        forgotPassword: {
          isLoading: false,
          isError: true,
          respId: action?.payload?.respId,
          code: action?.payload?.code,
          error: action?.payload?.errMsg,
          otpVerified: false,
        },
      };
    },

    // Reset Password
    [resetPassword.pending.toString()]: (state: any) => {
      return {
        ...state,
        resetPassword: { ...initialState.resetPassword },
      };
    },

    [resetPassword.fulfilled.toString()]: () => {
      return {
        ...initialState,
        // showLoginDialog: true,
        resetPassword: {
          ...initialState.resetPassword,
          success: true,
        },
      };
    },

    [resetPassword.rejected.toString()]: (state: any, action: any) => {
      return {
        ...state,
        resetPassword: {
          isLoading: false,
          isError: true,
          respId: action?.payload?.respId,
          code: action?.payload?.code,
          error: action?.payload?.errMsg,
        },
      };
    },

    // Resend OTP

    //  Send Login OTP
    [resendOTP.pending.toString()]: (state: any) => {
      return {
        ...state,
        sendOTP: {
          ...state.sendOTP,
          isResending: true,
          isResendError: false,
          resent: false,
          resendError: "",
        },
      };
    },

    [resendOTP.fulfilled.toString()]: (state: any, action: any) => {
      return {
        ...state,
        sendOTP: {
          ...state.sendOTP,
          isResending: false,
          otpSent: true,
          resent: true,
          augmentedHash: action?.payload?.data?.augmentedHash,
          phone: action?.payload?.data?.phoneNo,
          type: action?.payload?.type,
        },
      };
    },

    [resendOTP.rejected.toString()]: (state: any, action: any) => {
      return {
        ...state,
        sendOTP: {
          ...state.sendOTP,
          isResending: false,
          isResendError: true,
          resent: false,
          respId: action?.payload?.respId,
          code: action?.payload?.code,
          resendError: action?.payload?.errMsg,
        },
      };
    },

    // Email Login
    [autoLogin.pending.toString()]: () => {
      return {
        ...initialState,
        autoLogin: {
          ...initialState.autoLogin,
          isLoading: true,
        },
      };
    },

    [autoLogin.fulfilled.toString()]: (state: any, action: any) => {
      return {
        ...state,
        userInfo: action?.payload?.data,
        autoLogin: {
          isLoading: false,
          isError: false,
          error: false,
        },
      };
    },

    [autoLogin.rejected.toString()]: (state: any, action: any) => {
      return {
        ...state,
        autoLogin: {
          isLoading: false,
          isError: true,
          respId: action?.payload?.respId,
          code: action?.payload?.code,
          error: action?.payload?.errMsg,
        },
      };
    },
  },
});

export const {
  logoutUser,
  setTrackerState,
  clearSignUpError,
  signUpFailed,
  clickOnOtpPage,
  clickOnQuizPage,
  setPlan,
  clearPlan,
  setShowLogin,
  clearSendOTP,
  clearAuth,
  clearResendOTP,
  clearRedirect,
  setAuth,
  clearEmailLogin,
} = authSlice.actions;

export {
  fetchIpAddress,
  preSignup,
  verifySignupPayload,
  verifyOtp,
  fetchUserDetails,
} from "./auth/signup";
export {
  generateOtp,
  resendOTP,
  verifyOtpAndLogin,
  emailLogin,
  getUserPlan,
  autoLogin,
} from "./auth/login";
export { verifyforgotPasswordOTP, resetPassword } from "./auth/forgotPassword";

export default authSlice.reducer;
