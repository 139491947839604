import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { authenticatedAxios } from "../shared/setups/axios";

export const fetchUserPlanDetails = createAsyncThunk(
  "userPlans/getPlans",
  async ({ userId }: any, { rejectWithValue }: any) => {
    const res = await authenticatedAxios
      .post(`/api/v1/user/${userId}/allUserPlans?pageNo=0&pageSize=15`)
      .catch((error: any) => {
        throw rejectWithValue(error?.data || error?.response?.data);
      });

    return res.data;
  }
);

interface IUserPlanState {
  isLoading: boolean;
  plans: any;

  isError: boolean;
  error: string;
}

const initialState: IUserPlanState = {
  isLoading: false,
  isError: false,
  plans: {},
  error: "",
};

const userPlansSlice = createSlice({
  name: "userPlans",
  initialState,
  reducers: {
    clearUserPlans() {
      return initialState;
    },
  },

  extraReducers: (builder: any) => {
    builder.addCase(fetchUserPlanDetails.pending, () => {
      return {
        ...initialState,
        isLoading: true,
      };
    });

    builder.addCase(
      fetchUserPlanDetails.fulfilled,
      (state: IUserPlanState, action: PayloadAction<any>) => {
        return {
          ...state,
          isLoading: false,
          plans: action?.payload?.data,
        };
      }
    );

    builder.addCase(
      fetchUserPlanDetails.rejected,
      (state: IUserPlanState, action: PayloadAction<any>) => {
        return {
          ...state,
          isLoading: false,
          isError: true,
          code: action?.payload?.code,
          respId: action?.payload?.respId,
          error: action?.payload?.errMsg,
        };
      }
    );
  },
});

export const { clearUserPlans } = userPlansSlice.actions;

export default userPlansSlice.reducer;
