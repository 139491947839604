import { motion } from "framer-motion";
import { IconProps } from "../types";

const Loader = ({ color, size }: IconProps) => {
  return (
    <motion.div
      style={{
        padding: 4,
        minWidth: size || 24,
      }}
      animate={{
        rotate: 360,
      }}
      transition={{
        duration: 2,
        repeat: Infinity,
      }}
    >
      <motion.svg
        style={{
          marginRight: 0,
        }}
        xmlns="http://www.w3.org/2000/svg"
        width={size || 24}
        height={size || 24}
        viewBox="0 0 41 41"
        fill="none"
      >
        <rect x={17} width={8} height="7.80952" rx="3.90476" fill={color} />
        <rect
          x={5}
          y="4.88095"
          width={8}
          height="7.80952"
          rx="3.90476"
          fill={color}
        />
        <rect
          x={29}
          y="28.3095"
          width={8}
          height="7.80952"
          rx="3.90476"
          fill={color}
        />
        <rect
          y="16.5952"
          width={8}
          height="7.80952"
          rx="3.90476"
          fill={color}
        />
        <rect
          x={5}
          y="28.3095"
          width={8}
          height="7.80952"
          rx="3.90476"
          fill={color}
        />
        <rect
          x={29}
          y="4.88095"
          width={8}
          height="7.80952"
          rx="3.90476"
          fill={color}
        />
        <rect
          x={33}
          y="16.5952"
          width={8}
          height="7.80952"
          rx="3.90476"
          fill={color}
        />
        <path
          d="M17 37.0953C17 34.9387 18.7482 33.1905 20.9048 33.1905H21.0952C23.2518 33.1905 25 34.9387 25 37.0953V37.0953C25 39.2518 23.2518 41 21.0952 41H20.9048C18.7482 41 17 39.2518 17 37.0953V37.0953Z"
          fill={color}
        />
      </motion.svg>
    </motion.div>
  );
};
export default Loader;
