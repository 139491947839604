import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* eslint-disable @typescript-eslint/naming-convention */
// UTM Parameters are on the form utm_source=google&utm_medium=cpc&utm_campaign=brand
// fe-js uses utm parameters in snake_case

/* eslint-disable no-unused-vars */
// state objects not used in this slice

interface I_UTM {
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
  utm_term?: string | null;
}

const initialState: I_UTM = {
  utm_source: "",
  utm_medium: "",
  utm_campaign: "",
  utm_term: "",
};

const utmSlice = createSlice({
  name: "utmReducer",
  initialState,
  reducers: {
    clearUTM: (state: I_UTM) => {
      return initialState;
    },
    setUTM: (state: I_UTM, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    addUTM: (state: I_UTM, action: PayloadAction<I_UTM>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { clearUTM, setUTM, addUTM } = utmSlice.actions;

export default utmSlice.reducer;
