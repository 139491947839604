export const DEPENDENTS_CONTINUE = "dependent_continue_clicked";

export const D1_CONTINUE = "declaration1_continue_clicked";
export const D2_CONTINUE = "declaration2_continue_clicked";

export const CHECKOUT_PAYNOW = "make_payment";
export const CHECKOUT_EDITDEP = "checkout_editdependent_clicked";
export const CHECKOUT_WAITING_PERIOD = "checkout_waitingperiod_clicked";
export const CHECKOUT_OUTOFSCOPE = "checkout_outofscope_clicked";

export const CHECKOUT_APPLY_COUPON_CLICKED = "checkout_applycoupon_clicked";
export const CHECKOUT_COUPON_APPLIED = "checkout_coupon_applied";
export const CHECKOUT_COUPON_INVALID = "checkout_coupon_invalid";

export const CHECKOUT_24M = "checkout_24M_clicked";
export const CHECKOUT_12M = "checkout_12M_clicked";
export const CHECKOUT_6M = "checkout_6M_clicked";
export const CHECKOUT_3M = "checkout_3M_clicked";
export const CHECKOUT_1M = "checkout_1M_clicked";

export const PAYSTATUS_SUCCESS = "status_success_opened";
export const PAYSTATUS_SUCCESS_CONTINUE = "status_successcontinue_clicked";
export const PAYSTATUS_PENDING = "status_pending_opened";
export const PAYSTATUS_FAILED = "status_fail_opened";
export const PAYSTATUS_FAILED_TRYAGAIN = "statusfailed_tryagain_clicked";

export const PAYSTATUS_CONTACT = "statusfailed_contactsupport_clicked";
export const SORRY_CONTACT = "agecap_okay_clicked";

export const HEADER_PLANS = "web_our_plans_dropdown_clicked";
export const HEADER_HOSPITAL = "web_our_network_clicked";
export const HEADER_BLOG = "web_blog_clicked";

export const HOMEPAGE_VIEW = "homepage_viewed";
export const HOMEPAGE_COMPARE_PLANS = "homepage_compareplans_clicked";

export const PLAN_SELECTION_VIEW = "planselection_viewd";
export const PLAN_SELECTION_COMPARE_PLANS =
  "planselection_compareplans_clicked";

export const COMPARE_PLANS_VIEW = "comparison_viewed";

export const PLAN_PAGE_VIEW = "planpage_view";
export const PLAN_SUBSCRIBE_NOW = "plan_subscribenow_clicked";
export const PLAN_PAGE_VIEW_PLANS = "planpage_viewplans_clicked";

export const REFERRAL_DETAILS_VIEW = "referraldetails_view";
export const REFERRAL_DETAILS_SHARE = "referraldetails_sharereferral_click ";
export const TRACK_REFERRALS_VIEW = "trackreferral_view";
export const TRACK_REFERRALS_SHARE = "trackreferral_sharereferral_click";
export const TRACK_REFERRALS_REMIND = "trackreferral_remindthem_click";
