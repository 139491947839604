import Script from "next/script";

export const initGTag = () => {
  if (typeof window === "undefined") return;

  const dataLayer = window.dataLayer || [];

  function gtag() {
    // eslint-disable-next-line prefer-rest-params
    dataLayer.push(arguments);
  }

  window.dataLayer = dataLayer;
  window.gtag = gtag;

  gtag("js", new Date());
  gtag("config", process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID);
  gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    debug_mode: true,
  });
};

export const initGtag2 = () => {
  if (typeof window === "undefined") return;

  window.dataLayer = window.dataLayer || [];

  function gtag() {
    // eslint-disable-next-line prefer-rest-params
    dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_2);

  gtag("event", "conversion", {
    send_to: `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_2}/kW2cCILa1uwYENmGlvwo`,
  });
};

export const initGTM = () => {
  if (typeof window === "undefined") return;

  // if (window.gtag) return;

  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    // eslint-disable-next-line one-var, no-var
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.defer = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", process.env.NEXT_PUBLIC_GTM);
};

const GTagScript = () => {
  // if (load)
  return (
    <>
      <Script
        defer
        id="gtag-analytics"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script
        defer
        id="gtag-tracking"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID}`}
      />
      <Script defer id="gtag-init" strategy="lazyOnload">
        {initGTag()}
      </Script>
      <Script defer id="gtm-init" strategy="lazyOnload">
        {initGTM()}
      </Script>

      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_2}`}
      ></Script>
      <Script defer id="gtag-init2" strategy="lazyOnload">
        {initGtag2()}
      </Script>
    </>
  );

  // return null1;
};

export default GTagScript;
