import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// createSlice : API function to define logic , PayloadAction: Represent content of a single Action

import { authenticatedAxios } from "./../shared/setups/axios";

import {
  getPlanBreakup,
  getPaymentLink,
  getPaymentStatus,
} from "./onboarding/payment";
import { getBackendEventPlatformType } from "../components/Onboarding/onboarding.helper";
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 } from "uuid";
import { version } from "../package.json";

declare const window: any;

export const getConfig = createAsyncThunk(
  "onboarding/getConfig",
  async ({ groupId }: { groupId: string }, { rejectWithValue }: any) => {
    if (!groupId) {
      console.warn("config(): groupId missing");
      return;
    }

    const res = await authenticatedAxios
      .get(`/api/v2/plan-purchase/configuration/${groupId}`)
      .catch((error: any) => {
        console.log("Onboarding Config Error", error);

        throw rejectWithValue(error?.data || error?.response || error);
      });

    return res?.data;
  }
);

export const updateUserState: any = createAsyncThunk(
  "onboarding/updateUserState",
  async (payload: any, { rejectWithValue }: any) => {
    const authState = window?.store?.getState()?.auth;

    if (!authState?.userInfo) return;

    const res = await authenticatedAxios
      .post("api/v2/plan-purchase/updateUserState", payload)
      .catch((error: any) => {
        console.log("update state error", error);

        throw rejectWithValue(error?.data || error?.response || error);
      });

    return res?.data;
  }
);

export const validateCoupon: any = createAsyncThunk(
  "onboarding/validateCoupon",
  async (payload: any, { rejectWithValue }: any) => {
    const res = await authenticatedAxios
      .post("/api/v2/plan-purchase/validateDiscountCode", payload)
      .catch((error: any) => {
        console.log("apply coupon error", error);

        throw rejectWithValue(error?.data || error?.response || error);
      });

    return res?.data;
  }
);

type eventPayload = {
  event_type_id: string;
  event_id: string;
  event_name: string;
  business_flow: string;
  _v: string;
  uid?: string;
  event_attributes?: {};
};

type apiPayloadType = {
  // source: "MWEB" | "DWEB" | "IWEB_ANDROID" | "IWEB_IOS";
  source: string;
  device_id: string;
  timestamp: number;
  app_version: string;
};

export const backendDrivenEvents: any = createAsyncThunk(
  "onboarding/backendDrivenEvents",
  (payload: eventPayload) => {
    const { platform } = window?.store?.getState()?.onboarding;

    let device_id = localStorage.getItem("device_id");

    if (!device_id) {
      device_id = `w-${v4()}`;
      localStorage.setItem("device_id", device_id);
    }

    const apiPayload: apiPayloadType & eventPayload = {
      source: getBackendEventPlatformType(platform),
      device_id,
      timestamp: Date.now() / 1000,
      app_version: version,
      ...payload,
    };

    authenticatedAxios.post("/analytics-producer/v1/events", apiPayload);
  }
);

interface OnboardingState {
  isConfigLoading: boolean;
  isConfigError: boolean;
  configError: string;
  config: any;

  dependents: {
    self: boolean;
    spouse: boolean;
    children: number;
  };
  quiz: {
    activePage: number;
  };

  planPrice: number;

  isPlanBreakupLoading: false;
  planBreakup: any;
  isPlanBreakupError: false;
  planBreakupError: "";

  isPaymentLoading: false;
  isPaymentError: false;
  paymentError: string;
  payment: {
    payOption: string;
  };
  groupName: string;

  isPaymentStatusLoading: false;
  paymentStatus: any;
  isPaymentStatusError: false;
  paymentStatusError: string;

  showDownloadAppModal: boolean;

  platform: string | undefined;

  selectedTenure: any;

  couponCode: string;
  isCouponStatusLoading: boolean;
  isCouponStatusError: boolean;
  couponStatusError: string;
  couponStatus: any;
  couponRemoved: boolean;

  planGroups?: any;
  groupId: string;
  dsaPlan?: string;
}

const initialState: OnboardingState = {
  isConfigLoading: false,
  isConfigError: false,
  configError: "",
  config: {},
  dependents: {
    self: true,
    spouse: false,
    children: 0,
  },

  quiz: {
    activePage: 0,
  },

  planPrice: 0,

  isPlanBreakupLoading: false,
  planBreakup: [],
  isPlanBreakupError: false,
  planBreakupError: "",
  groupName: "",

  isPaymentLoading: false,
  isPaymentError: false,
  paymentError: "",
  payment: {
    payOption: "BULK",
  },

  isPaymentStatusLoading: false,
  paymentStatus: {},
  isPaymentStatusError: false,
  paymentStatusError: "",

  showDownloadAppModal: false,

  selectedTenure: {},

  platform: "",

  couponCode: "",
  isCouponStatusLoading: false,
  isCouponStatusError: false,
  couponStatusError: "",
  couponStatus: {},
  couponRemoved: false,

  planGroups: [],
  groupId: "",
  dsaPlan: "",
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setDependents(state: any, action: any) {
      return {
        ...state,
        dependents: {
          ...state.dependents,
          ...action.payload,
        },
      };
    },

    clearDependents(state: any) {
      return {
        ...state,
        dependents: {
          ...initialState.dependents,
        },
      };
    },

    setSelectedTenure(state: any, action: any) {
      return {
        ...state,
        selectedTenure: action?.payload,
      };
    },

    setQuiz(state: any, action: any) {
      return {
        ...state,
        quiz: {
          ...state.quiz,
          ...action.payload,
        },
      };
    },

    setQuizActivePage(state: any, action: any) {
      return {
        ...state,
        quiz: {
          ...state.quiz,
          ...action.payload,
        },
      };
    },

    setPlanPrice(state: any, action: any) {
      return {
        ...state,
        planPrice: action.payload,
      };
    },

    setPayment(state: any, action: any) {
      return {
        ...state,
        payment: {
          ...state.payment,
          ...action.payload,
        },
      };
    },

    clearPayment(state: any) {
      return {
        ...state,
        payment: {
          ...initialState.payment,
        },
      };
    },

    clearSelectedTenure(state: any) {
      return {
        ...state,
        selectedTenure: {},
      };
    },

    clearQuiz(state: any) {
      return {
        ...state,
        quiz: {
          ...initialState.quiz,
        },
      };
    },

    clearConfig(state: any) {
      return {
        ...state,
        isConfigLoading: false,
        isConfigError: false,
        configError: "",
        config: {},
      };
    },

    clearPaymentStatus(state: any) {
      return {
        ...state,
        isPaymentStatusLoading: false,
        paymentStatus: {},
        isPaymentStatusError: false,
        paymentStatusError: "",
      };
    },

    clearOnboarding(state: any) {
      return {
        ...initialState,
        couponStatus: state?.couponStatus,
        couponCode: state?.couponCode,
        platform: state?.platform,
      };
    },

    toggleDownloadAppModal(state: any) {
      return { ...state, showDownloadAppModal: !state.showDownloadAppModal };
    },

    setPlatformType(state: any, action: any) {
      return { ...state, platform: action?.payload };
    },

    clearCoupons(state: any) {
      return {
        ...state,
        couponCode: "",
        isCouponStatusLoading: false,
        isCouponStatusError: false,
        couponStatusError: "",
        couponStatus: {},
        couponMeta: {},
      };
    },

    setCouponRemoved(state: any, action: any) {
      return {
        ...state,
        couponRemoved: action?.payload,
      };
    },

    clearPaymentError(state: any) {
      return {
        ...state,
        paymentError: "",
      };
    },

    setPlanGroups(state: any, action: any) {
      return {
        ...state,
        planGroups: action?.payload,
      };
    },

    setGroupId(state: any, action: any) {
      return {
        ...state,
        groupId: action?.payload,
      };
    },

    setGroupName(state: any, action: any) {
      return {
        ...state,
        groupName: action?.payload,
      };
    },

    setDsaGroupName(state: any, action: any) {
      return {
        ...state,
        dsaPlan: action?.payload,
      };
    },
  },

  extraReducers: (builder) => {
    //  Get Config
    builder.addCase(getConfig.pending, (state: any) => {
      return {
        ...state,
        isConfigLoading: true,
        config: {},
      };
    });

    builder.addCase(getConfig.fulfilled, (state: any, action: any) => {
      const { data } = action?.payload || {};

      if (!data) return;

      console.log("Config Data", data);

      return {
        ...state,
        isConfigLoading: false,
        config: data,
      };
    });

    builder.addCase(getConfig.rejected, (state: any, action: any) => {
      const { error } = action?.payload || {};

      return {
        ...state,
        isConfigLoading: false,
        isConfigError: true,
        configError: error,
      };
    });

    // Get Plan Breakup
    builder.addCase(getPlanBreakup.pending, (state: any) => {
      return {
        ...state,
        isPlanBreakupLoading: true,
        planBreakup: [],
        isPlanBreakupError: false,
        planBreakupError: "",
      };
    });

    builder.addCase(getPlanBreakup.fulfilled, (state: any, action: any) => {
      const { data } = action?.payload || {};

      if (!data) return state;

      console.log("Data", data);

      return {
        ...state,
        isPlanBreakupLoading: false,
        planBreakup: data?.tenureBasePlanMapping || [],
        couponMeta: data?.couponMeta || {},
      };
    });

    builder.addCase(getPlanBreakup.rejected, (state: any, action: any) => {
      const { error } = action?.payload || {};

      return {
        ...state,
        isPlanBreakupLoading: false,
        isPlanBreakupError: true,
        planBreakupError: error,
      };
    });

    // Get Payment Link
    builder.addCase(getPaymentLink.pending, (state: any) => {
      return {
        ...state,
        isPaymentLoading: true,
        payment: {
          payOption: state.payment?.payOption,
        },
        paymentError: "",
      };
    });

    builder.addCase(getPaymentLink.fulfilled, (state: any, action: any) => {
      const { data } = action?.payload || {};

      return {
        ...state,
        isPaymentLoading: false,
        payment: {
          ...state.payment,
          ...data,
        },
      };
    });

    builder.addCase(getPaymentLink.rejected, (state: any, action: any) => {
      const error = action?.payload || {};

      return {
        ...state,
        isPaymentLoading: false,
        isPaymentError: true,
        paymentError: error,
      };
    });

    // Payment Status
    builder.addCase(getPaymentStatus.pending, (state: any) => {
      return {
        ...state,
        isPaymentStatusLoading: true,
        isPaymentStatusError: false,
        paymentStatus: {
          ...initialState.paymentStatus,
        },
        paymentStatusError: "",
      };
    });

    builder.addCase(getPaymentStatus.fulfilled, (state: any, action: any) => {
      const { data } = action?.payload || {};

      return {
        ...state,
        isPaymentLoading: false,
        paymentStatus: {
          ...data,
        },
      };
    });

    builder.addCase(getPaymentStatus.rejected, (state: any, action: any) => {
      const { error } = action?.payload || {};

      return {
        ...state,
        isPaymentStatusLoading: false,
        isPaymentStatusError: true,
        paymentStatusError: error,
      };
    });

    // updateUserState
    builder.addCase(updateUserState.fulfilled, (state: any) => {
      return {
        ...state,
      };
    });

    // applyCoupon
    builder.addCase(validateCoupon.pending, (state: any, action: any) => {
      const { data } = action.payload || {};

      return {
        ...state,
        couponCode: "",
        isCouponStatusLoading: false,
        couponStatus: {
          ...data,
        },
        isCouponStatusError: false,
        couponStatusError: "",
      };
    });

    builder.addCase(validateCoupon.fulfilled, (state: any, action: any) => {
      const { data } = action.payload || {};

      return {
        ...state,
        couponCode: data?.couponCode,
        isCouponStatusLoading: false,
        couponRemoved: false,
        couponStatus: {
          ...data,
        },
      };
    });

    builder.addCase(validateCoupon.rejected, (state: any, action: any) => {
      return {
        ...state,
        couponCode: "",
        isCouponStatusLoading: false,
        isCouponStatusError: true,
        couponStatusError: action?.payload?.errMsg,
        couponStatus: {},
        couponRemoved: true,
      };
    });
  },
});

export const {
  clearOnboarding,
  clearDependents,
  setDependents,
  setPayment,
  setPlanPrice,
  setQuizActivePage,
  setSelectedTenure,
  clearSelectedTenure,
  clearConfig,
  clearQuiz,
  clearPayment,
  clearPaymentStatus,
  toggleDownloadAppModal,
  setPlatformType,
  clearCoupons,
  clearPaymentError,
  setPlanGroups,
  setGroupId,
  setDsaGroupName,
  setGroupName,
  setCouponRemoved,
} = onboardingSlice.actions;

export { getPlanBreakup as getPlanBreakup } from "./onboarding/payment";
export { getPaymentLink as getPaymentLink } from "./onboarding/payment";
export { getPaymentStatus as getPaymentStatus } from "./onboarding/payment";

export default onboardingSlice.reducer;
