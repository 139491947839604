import React from "react";

export const imageParser = (file: string) => "/images/" + file;

export default function getScrollPercent() {
  return (
    ((document.documentElement.scrollTop + document.body.scrollTop) /
      (document.documentElement.scrollHeight -
        document.documentElement.clientHeight)) *
    100
  );
}

export const injectCSS = (styleString: string) => {
  const style = document.createElement("style");
  style.textContent = styleString;
  document.head.append(style);
};

export const replaceTextWithComponent = (
  string: string,
  text: string,
  component: React.Component
) => {
  if (!string || !text || !component) return null;

  const result: any = string.replace(text, ":").split(":");

  result[2] = result[1];
  result[1] = component;

  return result;
};

export const debounce = (fn: Function, ms: number = 300) => {
  if (!fn) return;

  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    // eslint-disable-next-line no-invalid-this
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};
