import React, { useEffect } from "react";
import appData from "../../package.json";

import { useIsomorphicEffect } from "./../hooks";

declare const window: any;
const version = appData.version;

const versionsMatch = (ver: string, globalVersion: string | null) => {
  console.log("App Versions", ver, globalVersion);

  if (!ver || !globalVersion) {
    return null;
  }

  if (ver === globalVersion) return true;

  return false;
};

const clearCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function (names) {
      for (const name of names) caches.delete(name);
    });
  }

  localStorage.setItem("appVersion", version);
  // Delete Browser Cache and Hard Reload
  window.location.reload();
};

const CacheBuster: any = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    window.appVersion = localStorage.getItem("appVersion");
  });

  useIsomorphicEffect(() => {
    console.log("Version Cache Buster Runs");

    if (typeof window === "undefined") return;

    const appVersion = localStorage.getItem("appVersion");
    const verRes = versionsMatch(version, appVersion);

    if (verRes === null && !appVersion) {
      localStorage.setItem("appVersion", version);
    }

    if (verRes === false) clearCacheAndReload();
  }, []);

  return children;
};

export default CacheBuster;
