import styled from "@emotion/styled";

const LWrapper = styled.div<any>`
  display: grid;

  background: ${(props: any) => props.background};
  color: ${(props: any) => props.color};

  font-family: "Poppins", "Roboto Slab", sans-serif;

  margin: 0;
  min-height: 100vh;

  grid-template-rows: auto 1fr ${(props: any) => (props.footer ? "auto" : "0")};
  grid-template-columns: 100%;

  .app-header {
    width: 100%;
    background: ${(props: any) => props?.headerProps?.background || "#003032"};
    color: ${(props: any) => props?.headerProps?.color};
    height: ${(props: any) => props?.headerProps?.height};
  }

  .app-main {
    width: 100%;
    height: 100%;

    background: ${(props: any) => props?.mainProps?.background};
    color: ${(props: any) => props?.mainProps?.color};
  }

  .app-nav {
    width: 100%;
    background-color: ${(props: any) =>
      props?.navProps?.background ||
      "var(--kenkoMobileSidebarMenuBackgroundColor)"};
    color: ${(props: any) => props?.navProps?.color};
    height: ${(props: any) => props?.navProps?.height || "100%"};
    width: ${(props: any) => props?.navProps?.width || ""};
    border-radius: ${(props: any) => props?.navProps?.borderRadius || ""};

    position: fixed;
    top: 0;
    right: 0;
    max-width: 400px;
    overflow: hidden;

    z-index: 1000;
    padding: 56px 0 0 20px;
  }

  .app-footer {
    width: 100%;
    background: ${(props: any) => props?.footerProps?.background};
    color: ${(props: any) => props?.footerProps?.color};
    position: ${(props: any) => props?.footerProps?.position};
    left: ${(props: any) => props?.footerProps?.left};
    right: ${(props: any) => props?.footerProps?.right};
    top: ${(props: any) => props?.footerProps?.top};
    bottom: ${(props: any) => props?.footerProps?.bottom};
  }
`;

const LayoutWrapper = ({
  header,
  footer,
  children,
  // nav,
  // open,
  background,
  color,

  headerProps,
  mainProps,
  navProps,
  footerProps,

  navState,
  navBar,
}: any) => {
  return (
    <LWrapper
      header={header}
      footer={footer}
      background={background}
      color={color}
      headerProps={headerProps}
      navProps={navProps}
      mainProps={mainProps}
      footerProps={footerProps}
    >
      {header && <header className="app-header"> {header} </header>}
      {navBar && navState && <div className="app-nav"> {navBar} </div>}
      <main className="app-main"> {children} </main>
      {footer && <footer className="app-footer">{footer}</footer>}
    </LWrapper>
  );
};

export default LayoutWrapper;
