import { routes } from "./../../../constants";

export const navLinks = {
  disclaimer:
    "Kenko is a healthcare provider. We partner directly with health and wellness services that guarantee benefits to our members in exchange for recurring payments. ",
  button: {
    text: "navLinks.button.text",
    link: routes.PLAN_SELECTION,
  },
  section1: {
    heading: "Our plans",
    link: "/plan-selection",
    links: [
      {
        text: "Our plans",
        url: "/plan-selection",
      },
    ],
  },
  section2: {
    heading: "Our Hospital Network",
    link: "/our-approved-hospitals",
    links: [
      {
        text: "Our Approved Hospitals",
        url: "/our-approved-hospitals",
      },
    ],
  },
  section3: {
    heading: "Blogs",
    link: "https://www.blog.kenkohealth.in/blog",
  },
};

export const emergency = {
  text: "Emergency ",
  phone: "+91 113839585",
};

export const buttons = {
  login: "Log In",
  signup: "Sign Up",
};
