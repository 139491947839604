import dynamic from "next/dynamic";

const FooterWrapper = dynamic(() =>
  import("./Blocks").then((mod) => mod.FooterWrapper)
);

const FooterWeb = dynamic(() =>
  import("./Blocks").then((mod) => mod.FooterWeb)
);

const FooterMobile = dynamic(() =>
  import("./Blocks").then((mod) => mod.FooterMobile)
);

const Footer = ({ data, showPlansSection }: any) => {
  return (
    <FooterWrapper>
      <FooterWeb
        className="webonly"
        data={data}
        showPlansSection={showPlansSection}
      />

      <FooterMobile className="mobileonly" data={data} />
    </FooterWrapper>
  );
};

export default Footer;
