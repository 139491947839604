import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authenticatedAxios, authPaymentAxios } from "./../shared/setups/axios";

import { enums } from "../shared/constants";

export const applyAddon = createAsyncThunk(
  "cart/applyAddon",
  async (
    {
      basePlanId,
      couponCodeId,
      planDuration,
    }: { basePlanId: string; couponCodeId?: string; planDuration: string },
    { rejectWithValue }: any
  ) => {
    const apiPayload = {
      basePlanId,
      planDuration,
      couponCodeId: couponCodeId || "",
      doubleCoverageFlag: false,
      annualFlag: false,
      versionSource: "FHC",
    };

    const res = await authPaymentAxios
      .post("/api/v2/payment/applyAddon", apiPayload)
      .catch((error) => {
        console.log("Apply Addon Error", error);

        throw rejectWithValue(error?.data || error?.response);
      });

    return res?.data;
  }
);

export const applySelectedAddon = createAsyncThunk(
  "cart/applySelectedAddon",
  async (
    {
      basePlanId,
      couponCodeId,
      planDuration,
    }: { basePlanId: string; couponCodeId?: string; planDuration: string },
    { rejectWithValue }: any
  ) => {
    const apiPayload = {
      basePlanId,
      couponCodeId: couponCodeId || "",
      planDuration,
      doubleCoverageFlag: false,
      annualFlag: false,
      versionSource: "FHC",
    };

    const res = await authPaymentAxios
      .post("/api/v2/payment/applyAddon", apiPayload)
      .catch((error) => {
        console.log("Apply Addon Error", error);

        throw rejectWithValue(error?.data || error?.response);
      });

    return res?.data;
  }
);

export const checkCouponCode = createAsyncThunk(
  "cart/checkCouponCode",
  async ({ couponCode }: { couponCode?: string }, { rejectWithValue }: any) => {
    const apiPayload = {
      couponCode: couponCode || "",
    };

    const res = await authenticatedAxios
      .post("/api/v1/devTest/checkCouponValid", apiPayload)
      .catch((error) => {
        console.log("Apply Addon Error", error);

        throw rejectWithValue(error?.data || error?.response);
      });

    return res?.data;
  }
);

interface ICartState {
  isLoading: boolean;
  isError: boolean;
  error: string;
  annual: any;
  semiannual: any;
  quarterly: any;
  monthly: any;
  selectedDuration: string;
  selectedAddon: any;
  coupon: any;
  couponCode: any;
  showMobileCart: any;
}

const initialState: ICartState = {
  isLoading: false,
  isError: false,
  showMobileCart: false,
  error: "",

  annual: {},
  semiannual: {},
  quarterly: {},
  monthly: {},
  selectedDuration: "",
  selectedAddon: {},
  couponCode: "",
  coupon: {},
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    clearCart: () => {
      return initialState;
    },

    setCouponCode: (state: any, action: any) => {
      return {
        ...state,
        couponCode: action?.payload,
      };
    },

    clearCouponCode: (state: any) => {
      return {
        ...state,
        couponCode: "",
        coupon: {},
      };
    },

    setSelectedDuration: (state: any, action: any) => {
      return {
        ...state,
        selectedDuration: action?.payload,
      };
    },

    clearCheckCouponCode: (state: any) => {
      return {
        ...state,
        coupon: {},
      };
    },

    setShowMobileCart: (state: any, action: any) => {
      console.log("enabilinggg mobile carttt", action?.payload);
      return {
        ...state,
        showMobileCart: action?.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(applyAddon.pending, () => {
      return {
        ...initialState,
        isLoading: true,
        selectedAddon: {},
      };
    });

    builder.addCase(applyAddon.fulfilled, (state: any, action: any) => {
      console.log("Apply Payload: ", action);

      const duration = action?.meta?.arg?.planDuration;

      const { data } = action?.payload || {};

      const addonData: any = {};

      if (duration === enums.MONTHLY) addonData.monthly = data;
      if (duration === enums.QUARTERLY) addonData.quarterly = data;
      if (duration === enums.SEMIANNUAL) addonData.semiannual = data;
      if (duration === enums.ANNUAL) addonData.annual = data;

      return {
        ...state,
        isLoading: false,
        ...addonData,
      };
    });

    builder.addCase(applyAddon.rejected, (state: any, action: any) => {
      const duration = action?.meta?.arg?.planDuration;

      const { code, respId, errMsg: error } = action.payload || {};

      const _data = {
        code,
        respId,
        error,
        isError: true,
      };

      const errorData: any = {};

      if (duration === enums.MONTHLY) errorData.monthly = _data;
      if (duration === enums.QUARTERLY) errorData.quarterly = _data;
      if (duration === enums.SEMIANNUAL) errorData.semiannual = _data;
      if (duration === enums.ANNUAL) errorData.annual = _data;

      return {
        ...state,
        isLoading: false,
        ...errorData,
      };
    });

    // For Selected Addon (after a addon duration has been selected)

    builder.addCase(applySelectedAddon.pending, (state: any) => {
      return {
        ...state,
        selectedAddon: {
          ...state.selectedAddon,
          isLoading: true,
          isError: false,
        },
      };
    });

    builder.addCase(applySelectedAddon.fulfilled, (state: any, action: any) => {
      const { data } = action?.payload || {};

      return {
        ...state,
        selectedAddon: {
          isLoading: false,
          ...data,
        },
      };
    });

    builder.addCase(applySelectedAddon.rejected, (state: any, action: any) => {
      const { code, respId, errMsg: error } = action.payload || {};

      return {
        ...state,
        selectedAddon: {
          ...state.selectedAddon,
          isLoading: false,
          code,
          respId,
          isError: true,
          error,
        },
      };
    });

    // For checking coupon code

    builder.addCase(checkCouponCode.pending, (state: any) => {
      return {
        ...state,
        coupon: {
          isLoading: true,
          isError: false,
        },
      };
    });

    builder.addCase(checkCouponCode.fulfilled, (state: any, action: any) => {
      const { data } = action?.payload || {};

      return {
        ...state,
        coupon: {
          isLoading: false,
          ...data,
        },
      };
    });

    builder.addCase(checkCouponCode.rejected, (state: any, action: any) => {
      const { code, respId, errMsg: error } = action.payload || {};

      return {
        ...state,
        coupon: {
          code,
          respId,
          isError: true,
          error,
        },
      };
    });
  },
});

export const {
  clearCart,
  setSelectedDuration,
  setCouponCode,
  clearCheckCouponCode,
  setShowMobileCart,
  clearCouponCode,
} = cartSlice.actions;

export default cartSlice.reducer;
