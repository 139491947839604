type Options = {
  [key: string]: string;
};

export const pushEvent = (name: string, { device, ...opts }: Options = {}) => {
  if (window?.Moengage) {
    window?.Moengage?.track_event(name, {
      device: device || window.screen.availWidth < 600 ? "mobile" : "desktop",
      ...opts,
    });
  }
};

const Moengage = {
  pushEvent: pushEvent,
  raw: (typeof window !== "undefined" && window?.Moengage) || null,
};

export default Moengage;
