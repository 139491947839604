import React from "react";

export const CloseIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#AAA8A8" />
      <g id="Draft 3">
        <path
          d="M-7398 -1344C-7398 -1345.1 -7397.1 -1346 -7396 -1346H2255C2256.1 -1346 2257 -1345.1 2257 -1344V10757C2257 10758.1 2256.1 10759 2255 10759H-7396C-7397.1 10759 -7398 10758.1 -7398 10757V-1344Z"
          fill="#282828"
        />
        <path
          d="M-7396 -1345H2255V-1347H-7396V-1345ZM2256 -1344V10757H2258V-1344H2256ZM2255 10758H-7396V10760H2255V10758ZM-7397 10757V-1344H-7399V10757H-7397ZM-7396 10758C-7396.55 10758 -7397 10757.6 -7397 10757H-7399C-7399 10758.7 -7397.66 10760 -7396 10760V10758ZM2256 10757C2256 10757.6 2255.55 10758 2255 10758V10760C2256.66 10760 2258 10758.7 2258 10757H2256ZM2255 -1345C2255.55 -1345 2256 -1344.55 2256 -1344H2258C2258 -1345.66 2256.66 -1347 2255 -1347V-1345ZM-7396 -1347C-7397.66 -1347 -7399 -1345.66 -7399 -1344H-7397C-7397 -1344.55 -7396.55 -1345 -7396 -1345V-1347Z"
          fill="white"
          fill-opacity="0.1"
        />
        <g id="iPhone 13 mini - 3" clip-path="url(#clip0_1971_2690)">
          <rect
            width="375"
            height="812"
            transform="translate(-335 -22)"
            fill="white"
          />
          <g id="Frame 1000005396" clip-path="url(#clip1_1971_2690)">
            <rect
              width="375"
              height="640"
              transform="translate(-335 -22)"
              fill="#003034"
            />
            <g id="Global Nav">
              <rect
                width="375"
                height="68"
                transform="translate(-335 -22)"
                fill="white"
              />
              <g id="Menu">
                <g id="Menu_2">
                  <g id="Group 10102">
                    <path
                      id="Vector"
                      opacity="0.87"
                      d="M-16.7305 19.3739C-16.7305 20.1549 -16.0973 20.7881 -15.3163 20.7881H7.30993C8.09094 20.7881 8.72408 20.1549 8.72408 19.3739C8.72408 18.5929 8.09094 17.9598 7.30993 17.9598H-15.3163C-16.0973 17.9598 -16.7305 18.5929 -16.7305 19.3739ZM-16.7305 12.3032C-16.7305 13.0842 -16.0973 13.7173 -15.3163 13.7173H7.30993C8.09094 13.7173 8.72408 13.0842 8.72408 12.3032C8.72408 11.5222 8.09094 10.8891 7.30993 10.8891H-15.3163C-16.0973 10.8891 -16.7305 11.5222 -16.7305 12.3032ZM-15.3163 3.81836C-16.0973 3.81836 -16.7305 4.45149 -16.7305 5.2325C-16.7305 6.01351 -16.0973 6.64664 -15.3163 6.64664H7.30993C8.09094 6.64664 8.72408 6.01351 8.72408 5.2325C8.72408 4.45149 8.09094 3.81836 7.30993 3.81836H-15.3163Z"
                      fill="#003032"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="Frame 1000005810">
            <g id="Rectangle 322570" filter="url(#filter0_d_1971_2690)">
              <rect x="-247" y="-22" width="287" height="812" fill="#FAF9F2" />
            </g>
            <g id="charm:cross">
              <path
                id="Vector_2"
                d="M16.875 7.125L7.125 16.875M7.125 7.125L16.875 16.875"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1971_2690"
          x="-261"
          y="-28"
          width="307"
          height="832"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.109804 0 0 0 0 0.109804 0 0 0 0 0.109804 0 0 0 0.14 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1971_2690"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1971_2690"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_1971_2690">
          <rect
            width="375"
            height="812"
            fill="white"
            transform="translate(-335 -22)"
          />
        </clipPath>
        <clipPath id="clip1_1971_2690">
          <rect
            width="375"
            height="640"
            fill="white"
            transform="translate(-335 -22)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
