import KenkoLogo from "../../../icons/KenkoLogo";
import Wrapper from "./../Wrapper/Wrapper";
import SectionWrapper from "../SectionWrapper";

import AuthButtons from "./Blocks/AuthButtons";

import styled from "@emotion/styled";

const MinimalHeaderWrapper = styled.div<any>`
  position: fixed;
  width: 100%;
  background: var(--biege50);
  z-index: 1000;

  .logo-style {
    cursor: pointer;
  }

  .min-header-limiter {
    overflow: initial !important;
  }

  .min-section-wrapper {
    position: relative;

    justify-content: left;
    align-items: center;
    background: transparent !important;
  }

  .auth-buttons {
    position: absolute;
    right: 3vw;

    .drop-links-links {
      z-index: 100;
    }
  }

  @media (max-width: 768px) {
    .auth-buttons {
      right: 10vw;

      .auth-text {
        display: none;
      }

      .drop-links-links {
        left: -100px !important;
      }
    }
  }
`;

const MinimalHeader = ({ padding, showAuth, stepper, onClick }: any) => {
  return (
    <>
      <MinimalHeaderWrapper>
        <SectionWrapper limiterClassName="min-header-limiter">
          <Wrapper className="min-section-wrapper" padding={padding || "30px"}>
            <KenkoLogo
              width={"126px"}
              height={"70px"}
              className="logo-style"
              onClick={onClick}
            />

            {showAuth && (
              <AuthButtons
                wrapperClass="auth-buttons"
                popupLeft="initial"
                popupRight="-10px"
              />
            )}
          </Wrapper>
        </SectionWrapper>
        {stepper}
      </MinimalHeaderWrapper>
      <div style={{ height: "120px" }} />
    </>
  );
};

export default MinimalHeader;
