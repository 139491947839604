import styled from "@emotion/styled";
import Button from "./../Button/Button";

import { ErrorOutline } from "@mui/icons-material";

import useRouter from "./../../hooks/useRouter";

const ErrorWrapper = styled.div`
  color: var(--kenkoDeepTeal);
  max-width: 600px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  margin: 18vh auto;

  .header {
    font-size: 20px;
    font-weight: 600;

    display: flex;
    align-items: center;
    gap: 10px;
  }

  .error-icon {
    color: var(--kenkoOrange);
    height: 56px;
    font-size: 42px;
  }

  .error-body {
    font-size: 18px;
  }

  .error-info {
    text-align: left;
    padding: 23px;
    background: var(--kenkoPureWhite);
    border: 10px;
    font-weight: 500;
  }

  .button {
    margin-block: 20px;
  }

  @media (max-width: 600px) {
    margin: 5vh auto;

    .header {
      font-size: 16px;
    }

    .error-icon {
      height: 36px;
      vertical-align: middle;
    }

    .error-body {
      font-size: 14px;
    }

    .error-info {
      font-size: 14px;
    }
  }
`;

const Error = ({ error, code, respId }: any) => {
  const router = useRouter();

  const redirectToHome = () => {
    router.push("/");
  };

  const handleRefresh = () => {
    router.push(window.location?.href);
  };

  return (
    <ErrorWrapper>
      <div className="header">
        <ErrorOutline className="error-icon" />
        <span className="heading">{error}</span>
      </div>
      <p className="error-body">
        We encountered an error that we are <br />
        trying to fix as soon as possible. For now,
        <br /> Please click button below to{" "}
        {error ? "go back to home" : "reload"}.
      </p>

      <div className={"button"}>
        <Button
          label={error ? "Back To Home" : "Reload"}
          className={"buttonComponent"}
          onClick={error ? redirectToHome : handleRefresh}
        ></Button>
      </div>

      <br />
      <div className={"error-info"}>
        <div> Status: {code || "N/A"}</div>
        <div> Ref Id: {respId || "N/A"}</div>
        <div> Location: {window.location?.href || "N/A"} </div>
      </div>
    </ErrorWrapper>
  );
};

export default Error;
