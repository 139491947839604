import WrapperItem from "./../WrapperItem/WrapperItem";
import { imageParser } from "../../../utils/image.utils";

const MinimalFooter = () => {
  return (
    <>
      <WrapperItem pop bottom="0px" left="0" right="0" className="webonly">
        <div
          style={{
            height: "20px",
            width: "100%",
            background: `url(${imageParser(
              "layout/bottom_pattern_desktop.svg"
            )})`,
          }}
        />
      </WrapperItem>
    </>
  );
};

export default MinimalFooter;
