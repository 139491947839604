import { Global, css } from "@emotion/react";
import * as colors from "./colors";

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        html {
          scroll-behavior: smooth;
          overflow-x: hidden;
          -webkit-overflow-scrolling: touch;
        }

        body {
          margin: 0;
          font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI,
            Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
            Helvetica Neue, sans-serif;
          background-color: #fcfcf8;
          overflow-x: hidden;
          -webkit-overflow-scrolling: touch;
          width: 100%;
          height: 100%;
          padding: 0 !important;
          font-display: swap;
        }

        a {
          color: inherit;
          text-decoration: none;
        }

        * {
          box-sizing: border-box;
        }

        .root {
          overflow: hidden;
          position: relative;
        }
        .item1 {
          width: 210%;
          overflow: hidden;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 5;
        }
        .item2 {
          width: 210%;
          overflow: hidden;
          bottom: 0;
          left: 0;
          z-index: 4;
          position: absolute;
        }

        .shoho-store-logo {
          height: 44px;
          width: auto;
          margin-bottom: 12px;
        }
        .icon-container{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin: 16px 16px 0px!important;
          @media (max-width: 600px) {
            margin: 0px!important;
            text-align:center;
          }
        }
       

        .subTitleM {
          font-family: "Poppins", "Roboto Slab", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 155%;
          letter-spacing: 0.001em;
          color: #003032;
          max-width: 340px;
          width: 100%;
          text-align: center;
          margin: auto;
        }

        .titleM {
          font-family: "Poppins", "Roboto Slab", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: 0.0125em;
          max-width: 302px;
          width: 100%;
          color: #003032;
          margin-bottom: 18px;
          text-align: center;
          margin: auto;
          margin-bottom: 0px;
        }

        .subTitle {
          font-family: "Poppins", "Roboto Slab", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 155%;
          letter-spacing: 0.001em;
          color: #003032;
          max-width: 480px;
          width: 100%;
          display: flex;
        }

        .title {
          font-family: "Poppins", "Roboto Slab", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 48px;
          line-height: 150%;
          letter-spacing: 0.0125em;
          max-width: 487px;
          width: 100%;
          color: #003032;
          margin: 0;
        }

        @-ms-viewport {
          width: device-width;
        }
        .slick-slide :focus {
          outline: none !important;
          -webkit-appearance: none !important;
        }

        :root {
          /**
           * @tokens Colors
           * @presenter Color 
           * */

          --kenkoDeepTeal: ${colors.kenkoDeepTeal};
          --kenkoGolden: ${colors.kenkoGolden};
          --kenkoOrange: ${colors.kenkoOrange};
          --kenkoOrange600: ${colors.kenkoOrange};
          --kenkoAvocado: ${colors.kenkoAvocado};
          --kenkoPureWhite: ${colors.kenkoPureWhite};
          --kenkoMobileSidebarMenuBackgroundColor : ${colors.kenkoMobileSidebarMenuBackgroundColor};
          
          --appleGreen200: ${colors.appleGreen200};
          --mossGreen50: ${colors.mossGreen50};
          --mossGreen100: ${colors.mossGreen100};
          --mossGreen200: ${colors.mossGreen200};
          --mossGreen700: ${colors.mossGreen700};

          --lightSprout: ${colors.lightSprout};
          --blue: ${colors.BLUE};

          --gray50: ${colors.gray50};
          --gray100: ${colors.gray100};
          --gray200: ${colors.gray200};
          --gray300: ${colors.gray300};
          --gray400: ${colors.gray400};
          --biege50: ${colors.biege50};
          --biege100: ${colors.biege100};
          --yellow50: ${colors.yellow50};
          --yellow500: ${colors.yellow500};

          --tabsBorderColor: ${colors.tabsBorderColor};
          --inputBorderColor: ${colors.inputBorderColor};
          --inputDisabledColor: ${colors.inputDisabledColor};
          --inputDisabledBorderColor: ${colors.inputDisabledBorderColor};
          --inactiveAccordianBorder: ${colors.inactiveAccordianBorder};

          /**
           * @tokens Shadows 
           * @presenter Shadow
           */

          --btnBoxShadow: ${colors.btnBoxShadow};
          --btnActiveShadow: ${colors.btnActiveShadow};

          --dialogShadow: ${colors.dialogShadow};
          --toggleSwitchShadow: ${colors.toggleSwitchShadow};
          --activeAccordianShadow: ${colors.activeAccordianShadow};

          /* Gray box shadows */
          --gray01dp: ${colors.gray01dp};
          --gray02dp: ${colors.gray02dp};
          --gray03dp: ${colors.gray03dp};
          --gray04dp: ${colors.gray04dp};
          --gray06dp: ${colors.gray06dp};
          --gray08dp: ${colors.gray08dp};
          --gray12dp: ${colors.gray12dp};
          --gray16dp: ${colors.gray16dp};
          --gray24dp: ${colors.gray24dp};

          /* Green box shadows */
          --green01dp: ${colors.green01dp};
          --green02dp: ${colors.green02dp};
          --green03dp: ${colors.green03dp};
          --green04dp: ${colors.green04dp};
          --green06dp: ${colors.green06dp};
          --green08dp: ${colors.green08dp};
          --green12dp: ${colors.green12dp};
          --green16dp: ${colors.green16dp};
          --green24dp: ${colors.green24dp};
        }

        .js-slider {
          & > .slide:not(:first-of-type) {
            display: none;
          }
        }

        .mobileRoot .slick-list {

          padding: 0 0 15px 0 !important;

          @media (max-width: 600px) {
            padding: 0 50px !important;
            margin: 0 -70px;
          }
        }

        .planListContainer .slick-slider {
          margin-right: 22px;

          @media (max-width: 1544px) {
            padding-left: 20px;
          }
          @media (max-width: 600px) {
            margin-right: 0;
            padding-left: 0;
          }
        }

        .error-container {
          color: var(--kenkoOrange);
        }

        /* Fonts: Poppins */
        @font-face {
          font-display: swap;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 300;
          src: url("../fonts/poppins-v20-latin-300.woff2") format("woff2"),
            url("../fonts/poppins-v20-latin-300.woff") format("woff");
        }

        @font-face {
          font-display: swap;
          font-family: "Poppins";
          font-style: italic;
          font-weight: 300;
          src: url("../fonts/poppins-v20-latin-300italic.woff2") format("woff2"),
            url("../fonts/poppins-v20-latin-300italic.woff") format("woff");
        }

        @font-face {
          font-display: swap;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          src: url("../fonts/poppins-v20-latin-regular.woff2") format("woff2"),
            url("../fonts/poppins-v20-latin-regular.woff") format("woff");
        }

        @font-face {
          font-display: swap;
          font-family: "Poppins";
          font-style: italic;
          font-weight: 400;
          src: url("../fonts/poppins-v20-latin-italic.woff2") format("woff2"),
            url("../fonts/poppins-v20-latin-italic.woff") format("woff");
        }

        @font-face {
          font-display: swap;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          src: url("../fonts/poppins-v20-latin-500.woff2") format("woff2"),
            url("../fonts/poppins-v20-latin-500.woff") format("woff");
        }

        @font-face {
          font-display: swap;
          font-family: "Poppins";
          font-style: italic;
          font-weight: 500;
          src: url("../fonts/poppins-v20-latin-500italic.woff2") format("woff2"),
            url("../fonts/poppins-v20-latin-500italic.woff") format("woff");
        }

        @font-face {
          font-display: swap;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          src: url("../fonts/poppins-v20-latin-600.woff2") format("woff2"),
            url("../fonts/poppins-v20-latin-600.woff") format("woff");
        }

        @font-face {
          font-display: swap;
          font-family: "Poppins";
          font-style: italic;
          font-weight: 600;
          src: url("../fonts/poppins-v20-latin-600italic.woff2") format("woff2"),
            url("../fonts/poppins-v20-latin-600italic.woff") format("woff");
        }

        @font-face {
          font-display: swap;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          src: url("../fonts/poppins-v20-latin-700.woff2") format("woff2"),
            url("../fonts/poppins-v20-latin-700.woff") format("woff");
        }

        @font-face {
          font-display: swap;
          font-family: "Poppins";
          font-style: italic;
          font-weight: 700;
          src: url("../fonts/poppins-v20-latin-700italic.woff2") format("woff2"),
            url("../fonts/poppins-v20-latin-700italic.woff") format("woff");
        }

        @font-face {
          font-display: swap;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 800;
          src: url("../fonts/poppins-v20-latin-800.woff2") format("woff2"),
            url("../fonts/poppins-v20-latin-800.woff") format("woff");
        }
        /* poppins-800italic - latin */
        @font-face {
          font-display: swap;
          font-family: "Poppins";
          font-style: italic;
          font-weight: 800;
          src: url("../fonts/poppins-v20-latin-800italic.woff2") format("woff2"),
            url("../fonts/poppins-v20-latin-800italic.woff") format("woff");
        }
        /* poppins-900 - latin */
        @font-face {
          font-display: swap;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 900;
          src: url("../fonts/poppins-v20-latin-900.woff2") format("woff2"),
            url("../fonts/poppins-v20-latin-900.woff") format("woff");
        }
        /* poppins-900italic - latin */
        @font-face {
          font-display: swap;
          font-family: "Poppins";
          font-style: italic;
          font-weight: 900;
          src: url("../fonts/poppins-v20-latin-900italic.woff2") format("woff2"),
            url("../fonts/poppins-v20-latin-900italic.woff") format("woff");
        }

        /* Fonts Roboto Slab */

        @font-face {
          font-display: swap;
          font-family: "Roboto Slab";
          font-style: normal;
          font-weight: 300;
          src: url("../fonts/roboto-slab-v24-latin-300.woff2") format("woff2"),
            url("../fonts/roboto-slab-v24-latin-300.woff") format("woff");
        }
        @font-face {
          font-display: swap;
          font-family: "Roboto Slab";
          font-style: normal;
          font-weight: 400;
          src: url("../fonts/roboto-slab-v24-latin-regular.woff2")
              format("woff2"),
            url("../fonts/roboto-slab-v24-latin-regular.woff") format("woff");
        }

        @font-face {
          font-display: swap;
          font-family: "Roboto Slab";
          font-style: normal;
          font-weight: 500;
          src: url("../fonts/roboto-slab-v24-latin-500.woff2") format("woff2"),
            url("../fonts/roboto-slab-v24-latin-500.woff") format("woff");
        }

        @font-face {
          font-display: swap;
          font-family: "Roboto Slab";
          font-style: normal;
          font-weight: 600;
          src: url("../fonts/roboto-slab-v24-latin-600.woff2") format("woff2"),
            url("../fonts/roboto-slab-v24-latin-600.woff") format("woff");
        }

        @font-face {
          font-display: swap;
          font-family: "Roboto Slab";
          font-style: normal;
          font-weight: 700;
          src: url("../fonts/roboto-slab-v24-latin-700.woff2") format("woff2"),
            url("../fonts/roboto-slab-v24-latin-700.woff") format("woff");
        }
        @font-face {
          font-display: swap;
          font-family: "Roboto Slab";
          font-style: normal;
          font-weight: 800;
          src: url("../fonts/roboto-slab-v24-latin-800.woff2") format("woff2"),
            url("../fonts/roboto-slab-v24-latin-800.woff") format("woff");
        }
        @font-face {
          font-display: swap;
          font-family: "Roboto Slab";
          font-style: normal;
          font-weight: 900;
          src: url("../fonts/roboto-slab-v24-latin-900.woff2") format("woff2"),
            url("../fonts/roboto-slab-v24-latin-900.woff") format("woff");
        @font-face {
          font-display: swap;
          font-family: "Figtree";
          font-style: normal;
          font-weight: 500;
          src: url("../fonts/Figtree-Medium.woff") format("woff"),
        }
        @font-face {
          font-display: swap;
          font-family: "Figtree";
          font-style: normal;
          font-weight: 600;
          src: url("../fonts/Figtree-SemiBold.woff") format("woff"),
        }
        // Styles for language dropdown on signup
        & .MuiPopover-root {
          & .MuiPaper-root.MuiMenu-paper {
            border-radius: 10px;
            box-shadow: 0px 3px 14px rgba(123, 123, 123, 0.12),
              0px 5px 5px rgba(123, 123, 123, 0.2);
          }

          & .MuiList-root.MuiMenu-list {
            padding: 0;
          }
        }
      `}
    />
  );
};

export default GlobalStyles;
