export const ORANGE = "#FF7056";
export const YELLOW = "#FBCE60";
export const BLUE = "#C2E6E0";
export const WHITE = "#EFEDD8";

export const D_GREEN = "#7F9E6D";

export const lightSprout = "#b4c688";
export const lightBg = "#fbfbf6";
export const neutralBg = "#f5f5f5";
export const neutralLightBg = "#f5f5f580";

export const addOpacity = (color: any) => (opacity: any) => color + opacity;

export const kenkoDeepTeal = "#003032";
export const kenkoGolden = "#fbce60";
export const kenkoOrange = "#ff7056";
export const kenkoOrange600 = "#ff7056";
export const kenkoAvocado = "#859862";
export const kenkoPureWhite = "#ffffff";
export const kenkoStraw = "#EFEDD8";
export const kenkoMobileSidebarMenuBackgroundColor = "#faf9f2";

export const appleGreen50 = "#eef4ef";
export const appleGreen200 = "#bad19d";

export const mossGreen50 = "#F4F7ED";
export const mossGreen100 = "#e3ebd2";
export const mossGreen200 = "#d1ddb7";
export const mossGreen400 = "#B4C688";
export const mossGreen700 = "#859862";

export const darkGreen400 = "#569091";
export const darkGreen800 = "#124647";

export const primaryGreen = "#20595A";

export const black400 = "#898989";
export const black900 = "#131313";
export const black800 = "#2B2B2B";

export const gray50 = "#f5f5f5";
export const gray100 = "#e9e9e9";
export const gray200 = "#d9d9d9";
export const gray300 = "#c4c4c4";
export const gray400 = "#9d9d9d";
export const gray800 = "#404040";
export const gray900 = "#1A1A1A";
export const biege50 = "#fbfbf6";
export const biege100 = "#faf9f2";
export const biege400 = "#EFEDD8";
export const yellow50 = "#fef7e3";
export const yellow500 = "#f8ba35";

export const teal100 = "#e5f3f3";
export const teal800 = "#034f50";

export const tabsBorderColor = "rgba(0, 48, 50, 0.2)";
export const inputBorderColor = "rgba(0, 48, 50, 0.5)";
export const inputDisabledColor = "rgba(0, 48, 50, 0.08)";
export const inputDisabledBorderColor = "rgba(0, 48, 50, 0.15)";
export const inactiveAccordianBorder = "rgba(0, 48, 50, 0.1)";
/**
 * @tokens Shadows
 * @presenter Shadow
 */

export const btnBoxShadow =
  "0px 5px 15px 0px rgba(103, 103, 102, 0.15), 0px 2px 4px 0px rgba(106, 105, 105, 0.2)";
export const btnActiveShadow = "inset 0px 4px 4px rgba(0, 0, 0, 0.25)";
export const dialogShadow =
  "0px 8px 10px 0px rgba(123, 123, 123, 0.2), 0px 6px 30px 0px rgba(123, 123, 123, 0.122)";
export const toggleSwitchShadow = "rgb(186, 209, 157, 0.3)";
export const activeAccordianShadow = "0px -2px 38px rgba(123, 123, 123, 0.09)";

/* Gray box shadows */
export const gray01dp =
  "0px 3px 1px rgba(123, 123, 123, 0.12), 0px 1px 5px rgba(123, 123, 123, 0.2)";
export const gray02dp =
  "0px 2px 2px rgba(123, 123, 123, 0.14), 0px 3px 1px rgba(123, 123, 123, 0.12), 0px 1px 5px rgba(123, 123, 123, 0.2)";
export const gray03dp =
  "0px 3px 4px rgba(123, 123, 123, 0.14), 0px 3px 3px rgba(123, 123, 123, 0.12),0px 1px 8px rgba(123, 123, 123, 0.2)";
export const gray04dp =
  "0px 4px 5px rgba(123, 123, 123, 0.14), 0px 1px 10px rgba(123, 123, 123, 0.12), 0px 2px 4px rgba(123, 123, 123, 0.2)";
export const gray06dp =
  "0px 6px 10px rgba(123, 123, 123, 0.14), 0px 1px 18px rgba(123, 123, 123, 0.12), 0px 3px 5px rgba(123, 123, 123, 0.2)";
export const gray08dp =
  "0px 8px 10px rgba(123, 123, 123, 0.14), 0px 3px 14px rgba(123, 123, 123, 0.12), 0px 5px 5px rgba(123, 123, 123, 0.2)";
export const gray12dp =
  "0px 12px 17px rgba(123, 123, 123, 0.14), 0px 5px 22px rgba(123, 123, 123, 0.12), 0px 7px 8px rgba(123, 123, 123, 0.2)";
export const gray16dp =
  "0px 6px 30px rgba(123, 123, 123, 0.12), 0px 8px 10px rgba(123, 123, 123, 0.2)";
export const gray24dp =
  "0px 24px 38px rgba(123, 123, 123, 0.14), 0px 9px 46px rgba(123, 123, 123, 0.12), 0px 11px 15px rgba(123, 123, 123, 0.2)";

/* Green box shadows */
export const green01dp =
  "0px 4px 5px rgba(32, 89, 90, 0.14), 0px 1px 10px rgba(32, 89, 90, 0.12), 0px 2px 4px rgba(32, 89, 90, 0.2)";
export const green02dp =
  "0px 2px 2px rgba(32, 89, 90, 0.14), 0px 3px 1px rgba(32, 89, 90, 0.12), 0px 1px 5px rgba(32, 89, 90, 0.2)";
export const green03dp =
  "0px 3px 4px rgba(32, 89, 90, 0.14), 0px 3px 3px rgba(32, 89, 90, 0.12), 0px 1px 8px rgba(32, 89, 90, 0.2)";
export const green04dp =
  "0px 4px 5px rgba(32, 89, 90, 0.14), 0px 1px 10px rgba(32, 89, 90, 0.12), 0px 2px 4px rgba(32, 89, 90, 0.2)";
export const green06dp =
  "0px 6px 10px rgba(32, 89, 90, 0.14), 0px 1px 18px rgba(32, 89, 90, 0.12), 0px 3px 5px rgba(32, 89, 90, 0.2)";
export const green08dp =
  "0px 8px 10px rgba(32, 89, 90, 0.14), 0px 3px 14px rgba(32, 89, 90, 0.12), 0px 5px 5px rgba(32, 89, 90, 0.2)";
export const green12dp =
  "0px 12px 17px rgba(32, 89, 90, 0.14), 0px 5px 22px rgba(32, 89, 90, 0.12), 0px 7px 8px rgba(32, 89, 90, 0.2)";
export const green16dp =
  "0px 16px 24px rgba(32, 89, 90, 0.14), 0px 6px 30px rgba(32, 89, 90, 0.12), 0px 8px 10px rgba(32, 89, 90, 0.2)";
export const green24dp =
  "0px 24px 38px rgba(32, 89, 90, 0.14), 0px 9px 46px rgba(32, 89, 90, 0.12), 0px 11px 15px rgba(32, 89, 90, 0.2)";
