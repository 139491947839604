import styled from "@emotion/styled";

interface SectionProps {
  background?: string;
  className?: string;
  style?: any;
  limiterClassName?: string;
  limiterStyle?: any;
  children: React.ReactNode;
}

const SectionWrapperStyles = styled.div<SectionProps>`
  background: ${(props: any) => props.background || ""};
  width: 100%;

  .benefit-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: stretch;
    grid-template-rows: 260px;
    gap: 25px;
    padding: 0 5rem 64px 5rem;
  }

  .limiter {
    max-width: 1536px;
    overflow: hidden;
    margin: 0 auto;
  }

  .benefit-section-title {
    color: #243242;
    text-align: center;
    font-family: Figtree;
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  @media (max-width: 600px) {
    .hospital-title {
      font-size: 18px;
    }

    .benefit-title-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .benefit-section-title {
      font-size: 20px;
      max-width: 12rem;
    }

    .benefit-container {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      width: 100%;
      gap: 40px;
      padding: 0 0 34px 0;
    }
  }
`;

export const BenefitCard = styled.div`
  padding: 0 1rem;
  .benefit-card {
    overflow: hidden;
    height: 100%;
    background-color: #fbfbf6;
    width: fit-content;
    border-radius: 16px;
    box-shadow: 10px 10px 0px 0px #d3d1b6;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .benefit-card-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .benefit-image {
    margin-top: 30px;
  }

  .benefit-image-other {
    margin-top: 4rem;
  }

  .benefit-title {
    margin: 0;
    color: #243242;
    text-align: center;
    font-family: Figtree;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 20px 0 0 0;
  }

  .benefit-description {
    color: #243242;
    text-align: center;
    font-family: Figtree;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    flex-grow: 2;
    width: 20ch;
    padding: 8px;
    margin: 0;
  }

  .benefit-footer {
    background-color: #f6f6f6;
    margin-top: auto;
    padding: 8px 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 600px) {
    .benefit-card {
      padding: 14px 16px 14px 13px;
    }
    .benefit-card-content {
      gap: 18px;
      flex-direction: row;
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .benefit-card-content div {
      display: flex;
      /* border:1px solid red; */
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .benefit-image {
      margin: 0;
    }

    .benefit-image-mobile {
      width: 50px;
      height: 50px;
    }
    .benefit-image-other {
      margin-top: 1rem;
    }
    .benefit-description {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      text-align: start;
      font-size: 12px;
      line-height: normal;
    }
    .benefit-title {
      padding: 0;
      font-size: 18px;
    }

    .benefit-footer {
      display: none;
    }
  }
`;

const SectionWrapper = ({
  background,
  className,
  style,
  limiterClassName,
  limiterStyle,
  children,
}: SectionProps) => {
  return (
    <SectionWrapperStyles
      style={style}
      className={className}
      background={background}
      data-key="limiter-wrapper"
    >
      <div className={"limiter " + limiterClassName} style={limiterStyle}>
        {children}
      </div>
    </SectionWrapperStyles>
  );
};

export default SectionWrapper;
