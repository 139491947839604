import { useState, useEffect, useRef } from "react";

function useInterval(callback: any, delay: number) {
  const savedCallback = useRef<any>(null);

  const [paused, setPaused] = useState<any>(null);
  const [intervalId, setIntervalId] = useState<any>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // console.log("Run", paused, delay);

    if (paused) {
      clearInterval(intervalId);
      return () => {};
    }

    function tick() {
      savedCallback?.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      setIntervalId(id);

      return () => clearInterval(id);
    }
  }, [paused]);

  return {
    pause: () => setPaused(true),
    play: () => setPaused(false),
  };
}

export default useInterval;
