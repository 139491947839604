import styled from "@emotion/styled";
import { useRef } from "react";

import { Wrapper, WrapperItem } from "./../";

import { navLinks } from "../Header/header.trans";
import { useTranslation } from "next-i18next";

import { GetACallBack } from "../Header/Header";
import { AuthButtons } from "./../Header/Blocks/AuthButtons";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { CloseIcon } from "../../../icons/CloseIcon";

const NavWrapper = styled.div`
  padding: 0 20px;
  height: 100%;

  .mobile-navlinks {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;

    .link {
      font-weight: 500px;
      font-size: 16px;

      justify-content: space-between;
      padding: 12px;
    }
  }

  .auth-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 25px;
  }

  .callback-form {
    width: 100%;

    input {
      padding-block: 10px;
    }
  }

  .mobile-auth-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    > * {
      flex: 1;
      display: block;
      width: 100%;
      text-align: center;
    }

    .button-signup {
      width: 100%;
      display: block;
      flex: 1;
      padding: 8px 20px;
    }

    .button-login {
      padding: 8px 20px;
    }
  }

  .close-icon-button {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 22px;
    right: 16px;
    border: none;
    outline: none;
    background: transparent;
  }
`;

const NavigationBar = ({ closeNav }: any) => {
  const { t } = useTranslation("header");
  const ref = useRef(null);

  useOnClickOutside(ref, closeNav);

  return (
    <NavWrapper ref={ref}>
      <Wrapper className="mobile-navlinks" align="center" spacing="50px">
        <a href={navLinks?.button?.link}>
          <Wrapper className="link" align="center">
            <WrapperItem>{t(navLinks?.section1?.heading)}</WrapperItem>
          </Wrapper>
        </a>

        <a href={navLinks?.section2?.link}>
          <Wrapper align="center" className="link">
            <WrapperItem>{t(navLinks?.section2?.heading)} </WrapperItem>
          </Wrapper>
        </a>

        <a href={navLinks?.section3?.link}>
          <Wrapper align="center" className="link">
            <WrapperItem>{t(navLinks?.section3?.heading)}</WrapperItem>
          </Wrapper>
        </a>
      </Wrapper>

      <GetACallBack />

      <button onClick={closeNav} className="close-icon-button">
        <CloseIcon />
      </button>

      <div className="auth-section">
        <AuthButtons
          wrapperClass="mobile-auth-buttons"
          linebreak={true}
          popupLeft="0"
        />
      </div>
    </NavWrapper>
  );
};

export default NavigationBar;
