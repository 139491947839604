import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { authenticatedAxios } from "./../shared/setups/axios";

import { fetchUserDetails } from "./auth-slice";

import dispatcher from "../shared/libs/eventsDispatcher";

const QUESTIONAIRE_ID = "624d4714833fe11067ea8be9";

export const getQuiz = createAsyncThunk(
  "quiz/getQuiz",
  async ({ userId }: { userId: string }, { rejectWithValue }: any) => {
    if (!userId) throw new Error("User ID is required");

    const apiPayload = {
      userId,
      utm_campaign: "",
      qnId: QUESTIONAIRE_ID,
      versionSource: "FHC",
    };

    const res = await authenticatedAxios
      .post("/api/v2/quiz/userQuiz/new", apiPayload)
      .catch((error) => {
        console.log("Quiz Error", error);

        throw rejectWithValue(error?.data || error.response);
      });

    return res?.data;
  }
);

export const saveQuiz = createAsyncThunk(
  "quiz/saveQuiz",
  async (payload: {
    userId: string;
    savedTillPageNo: number;
    utm_medium?: string;
    utm_campaign?: string;
    utm_source?: string;
  }) => {
    const api_payload: any = {
      ...payload,
      qnId: QUESTIONAIRE_ID,
      utm_campaign: payload.utm_campaign || "",
    };

    if (process.env.NEXT_PUBLIC_HEALTH_CAMPAIGN === "true")
      api_payload.versionSource = "FHC";

    const res: any = await authenticatedAxios
      .post("/api/v2/quiz/userQuiz/save", api_payload)
      .then((data: any) => {
        window?.Moengage?.track_event("web_quiz_question_answered", {
          value: 0,
          device: window.screen.availWidth < 600 ? "mobile" : "desktop",
        });

        return data;
      })
      .catch((error) => {
        console.log("Quiz Save Error:", error?.response?.data, error);
      });

    return res?.data;
  }
);

export const submitQuiz = createAsyncThunk(
  "quiz/submitQuiz",
  async (payload: any, { rejectWithValue, dispatch }: any) => {
    const api_payload = {
      ...payload,
      qnId: QUESTIONAIRE_ID,
      utm_campaign: payload?.utm_campaign || "",
    };

    if (process.env.NEXT_PUBLIC_HEALTH_CAMPAIGN === "true")
      api_payload.versionSource = "FHC";

    const res: any = await authenticatedAxios
      .post("/api/v2/quiz/userQuiz/submit", api_payload)
      .then((data: any) => {
        window?.Moengage?.track_event("web_kenko_quiz_finish_button_clicked", {
          device: window.screen.availWidth < 600 ? "mobile" : "desktop",
        });
        return data;
      })
      .catch((error) => {
        throw rejectWithValue(error.response);
      });

    if (res?.data)
      dispatch(
        fetchUserDetails({
          userId: payload?.userId,
        })
      );

    return res?.data;
  }
);

export const initialState = {
  code: 0,
  quizData: {}, // list of questions fetched from the API
  currentPage: -1, // keeps track of current page
  groupQuestions: [], // groups with flattened list of questions to be displayed on current page
  activeGroup: -1, // keeps track of current active question (index/es)

  answers: {}, // key-value pair of answers, key = QuestionID
  quizAnswers: {}, // copy of quiz object with answers
  message: "",

  respId: "",
  isLoading: false,
  isError: false,
  error: "",
  isSubmitting: false,
  submitted: false,
  api: "",
};

const quizSlice: any = createSlice({
  name: "quiz",
  initialState: initialState,

  reducers: {
    setQuizPlex: () => {
      return initialState;
    },

    clearQuizPlex: () => {
      return initialState;
    },

    setQuizDataPlex: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        quizData: action?.payload,
      };
    },

    setCurrentPagePlex: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        currentPage: action?.payload,
      };
    },

    setGroupQuestionsPlex: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        groupQuestions: action?.payload,
      };
    },

    setActiveGroupPlex: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        activeGroup: action?.payload,
      };
    },

    setAnswersPlex: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        answers: action?.payload,
      };
    },

    setQuizAnswersPlex: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        quizAnswers: action?.payload,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getQuiz.pending, () => {
      return {
        ...initialState,
        isLoading: true,
      };
    });

    builder.addCase(
      getQuiz.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        console.log("Quiz Payload: ", action?.payload);

        const { code, respId, data } = action?.payload || {};
        const new_payload = {
          ...initialState,
          isLoading: false,
          code: code,
          respId: respId,
          data: data,
          quizData: data?.quiz,
          quizAnswers: data?.quiz,
          currentPage: data?.quiz?.gotoPage - 1 || 0,
          activeGroup: data?.quiz?.gotoGroup - 1 || 0,
        };
        return new_payload;
      }
    );

    builder.addCase(
      getQuiz.rejected,
      (state: any, action: PayloadAction<any>) => {
        const data = action?.payload?.data;

        return {
          ...initialState,
          isLoading: false,
          isError: true,
          code: data?.code || 0,
          error: data?.errMsg || "-",
          respId: data?.respId || "-",
          api: "getQuiz",
        };
      }
    );

    builder.addCase(submitQuiz.pending, (state: any) => {
      return {
        ...state,
        isSubmitting: true,
      };
    });

    builder.addCase(
      submitQuiz.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        console.log("Quiz Payload Fullfilled: ", action?.payload);

        dispatcher.pushEvent({
          type: "SET_REWARD_MODAL_STATUS",
          payload: true,
        });

        localStorage.setItem("SET_REWARD_MODAL_STATUS", "true");

        return {
          ...initialState,
          isSubmitting: false,
          submitted: true,
        };
      }
    );

    builder.addCase(
      submitQuiz.rejected,
      (state: any, action: PayloadAction<any>) => {
        return {
          ...state,
          isSubmitting: false,
          error: action?.payload?.data?.errMsg || "-",
        };
      }
    );
  },
});

export const {
  setQuizPlex,
  clearQuizPlex,
  setQuizDataPlex,
  setCurrentPagePlex,
  setGroupQuestionsPlex,
  setActiveGroupPlex,
  setAnswersPlex,
  setQuizAnswersPlex,
  toggleFooter,
} = quizSlice.actions;

export default quizSlice.reducer;
