import { useState, useEffect, useMemo } from "react";

const usePoller = ({
  func,
  duration,
  paused,
}: {
  func: Function;
  duration: number;
  paused: boolean;
}) => {
  const [ref, setRef] = useState<any>(null);

  const handleClean = () => {
    clearInterval(ref);
    // setRef(null);
  };

  useEffect(() => {
    return () => clearInterval(ref);
  }, []);

  useEffect(() => {
    if (ref) handleClean();

    let _ref: any = null;

    if (!paused) {
      _ref = setInterval(func, duration);
      setRef(_ref);
    }

    return () => clearInterval(_ref);
  }, [paused, func, duration]);

  return {
    ref,
    stop: handleClean,
  };
};

export default usePoller;
