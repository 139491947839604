import { useRouter as _useRouter } from "next/router";

export const useRouter = () => {
  const router = _useRouter();

  return {
    ...router,
    push: (url: string) => window && window.location.assign(url),
  };
};

export default useRouter;
