import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useRouter as useInternalRouter } from "./useRouter";
import { useSelector, useDispatch } from "react-redux";

import { getQuiz } from "./../../slices/quiz.slice";

import { routes } from "../constants";

const authRoutes: Array<any> = [
  routes.KENKO_SCORE_PAGE,
  routes.CHOOSE_PLAN_PAGE,
  routes.MY_PLANS_PAGE,
  routes.START_PAGE,
];
const Pages200: Array<any> = [routes.START_PAGE];
const Pages214: Array<any> = [routes.CHOOSE_PLAN_PAGE, routes.CART_PAGE];

import { shouldRedirectToNewFlow } from "../utils/helper.utils";
import useHasMounted from "./useHasMounted";

const useRedirections = () => {
  const router = useRouter();
  const iRouter = useInternalRouter();
  const dispatch = useDispatch<any>();

  const _persist = useSelector((state: any) => state._persist);
  const { userInfo } = useSelector((state: any) => state.auth);
  const { code } = useSelector((state: any) => state.quiz);

  useEffect(() => {
    if (!_persist?.rehydrated) return;

    if (!userInfo) router.push(routes.SIGNUP_PAGE);

    if (!code && userInfo && userInfo?.userId) {
      dispatch(getQuiz({ userId: userInfo?.userId }));
    }
  }, [_persist.rehydrated, userInfo, code]);

  useEffect(() => {
    // Check if the redux store has finished persisting...
    if (!_persist.rehydrated) return;

    if (!router?.pathname) return;

    const path = router?.pathname;

    // Check if the current path requires authentication
    if (authRoutes?.includes(path)) {
      // Is user logged in?
      if (!userInfo?.userId) router.push(routes.SIGNUP_PAGE);

      // User should be able to visit his profile.
      if (path === routes.MY_PLANS_PAGE) return;

      // Quiz Not Given By User
      if (code === 200) {
        // If the current Page is ineligible
        if (!Pages200?.includes(path)) {
          router.push(routes.START_PAGE);
        }
      }

      // B2C User, Quiz Given, Eligible for Plans
      if (code === 214 || code === 221) {
        // If the current page is ineligible
        if (!Pages214?.includes(path)) {
          router.push(routes.CHOOSE_PLAN_PAGE);
        }
      }

      if (code === 215) {
        // Disable for now
        // router.push(routes.BOOK_SLOT_PAGE);

        router.push(routes.MY_PLANS_PAGE);
      }

      // User is not eligible for Plan, or already have a plan
      // but logged in, or tried to vist choose-plan, etc.
      if (code === 220) {
        iRouter.push(routes.MY_PLANS_PAGE);
        // router.push(routes.KENKO_END_PAGE);
      }

      // B2B User : Given Quiz, Booked Slot
      if (code === 222) {
        router.push(routes.MY_PLANS_PAGE);
      }
    }
  }, [_persist.rehydrated, code]);

  return null;
};

export const useRedirect = ({
  redirect,
  auth,
  check,
}: {
  redirect?: boolean;
  auth?: boolean;
  check?: boolean;
}) => {
  const router = useRouter();
  const { userInfo, forgotPasswordState, phoneLogin, emailLogin, autoLogin } =
    useSelector((state: any) => state.auth);
  const { groupId } = useSelector((state: any) => state.onboarding);
  const { rehydrated } = useSelector((state: any) => state._persist);

  const mounted = useHasMounted();

  const utm = useSelector((state: any) => state.utmReducer);

  const [checked, setChecked] = useState(check ? false : true);

  useEffect(() => {
    if (!rehydrated) return;

    if (auth && !userInfo?.userId) router.push(routes.LOGIN_PAGE);
  }, [rehydrated, auth, userInfo?.userId]);

  useEffect(() => {
    if (autoLogin?.isError) router.push(routes.LOGIN_PAGE);
  }, [autoLogin]);

  useEffect(() => {
    if (phoneLogin?.isError) router.push(routes.LOGIN_PAGE);
  }, [phoneLogin]);

  useEffect(() => {
    if (emailLogin?.isError) router.push(routes.LOGIN_PAGE);
  }, [emailLogin]);

  useEffect(() => {
    if (
      autoLogin?.isLoading ||
      forgotPasswordState?.isLoading ||
      phoneLogin?.isLoading ||
      emailLogin?.isLoading
    ) {
      setChecked(true);
      return;
    }

    if (
      auth &&
      checked &&
      (!userInfo?.userId || (userInfo?.newUser && !userInfo?.accessToken))
    ) {
      router.push(routes.LOGIN_PAGE);
    }

    // console.log("Mounted", !redirect, !mounted, router.query.path === "new");

    if (!redirect || !mounted || router.query.path === "new") return;

    console.log("Check", userInfo?.user?.userStatus === "REGISTERED", redirect);

    if (userInfo?.user?.userStatus === "REGISTERED" && redirect)
      router.push(
        shouldRedirectToNewFlow(utm)
          ? groupId
            ? routes.PURCHASE_PATH_DEPENDENTS
            : routes.PLAN_SELECTION
          : routes.START_PAGE
      );
    else if (userInfo?.user?.userStatus) router.push(routes.MY_PLANS_PAGE);
  }, [userInfo?.userId, mounted]);
};

export default useRedirections;
