import { createAsyncThunk } from "@reduxjs/toolkit";
import md5 from "md5";

import { authenticatedAxios } from "../../shared/setups/axios";
import Moengage from "../../shared/utils/moengage.utils";
import { setShowLogin, clearSendOTP } from "../auth-slice";
import GA from "./../../shared/utils/ga.utils";
import { signupSuccess } from "../tracker.slice";
import { hash } from "../../shared/utils/helper.utils";
import { pushPixel } from "../../shared/utils/tracker.utils";
import { getPlatformType } from "../../components/Onboarding/onboarding.helper";

declare const window: any;

export const getOnboarding: any = createAsyncThunk(
  "auth/getOnboarding",
  async (payload: any) => {
    const { userId } = payload;

    const res = await authenticatedAxios
      .post("/api/v2/plan/userPlan/getOnboarding", { userId })
      .catch((error) => {
        console.log("Fetch onboarding data -> Failure", error);
      });

    return res?.data;
  }
);

export const generateOtp: any = createAsyncThunk(
  "auth/login/generateOtp",
  async ({ phone, type }: any, { rejectWithValue }: any) => {
    const payload = {
      phoneNo: phone,
      type,
    };

    const res = await authenticatedAxios
      .post("/api/v2/internal/generateOtp", payload)
      .catch((error) => {
        console.log("Send OTP Error", error);

        throw rejectWithValue(error?.data || error?.response?.data);
      });

    return {
      ...res?.data,
      type,
    };
  }
);

export const resendOTP: any = createAsyncThunk(
  "auth/login/resendOTP",
  async ({ phone, type }: any, { rejectWithValue }: any) => {
    const payload = {
      phoneNo: phone,
      type,
    };

    const res = await authenticatedAxios
      .post("/api/v2/internal/generateOtp", payload)
      .catch((error) => {
        console.log("Send OTP Error", error);

        throw rejectWithValue(error?.data || error?.response?.data);
      });

    return {
      ...res?.data,
      type,
    };
  }
);

export const verifyOtpAndLogin: any = createAsyncThunk(
  "auth/verifyOtpAndLogin",
  async (payload: any, { rejectWithValue, dispatch }: any) => {
    const { platform } = window?.store?.getState()?.onboarding;

    const currentPlatform = getPlatformType(platform);

    const res = await authenticatedAxios
      .post("/api/v2/internal/verifyOtpAndLogin", payload)
      .catch((error: any) => {
        console.log("Login w/ OTP", error);

        throw rejectWithValue(error?.response?.data || error?.data);
      });

    if (res.status === 200) {
      dispatch(getOnboarding({ userId: res.data?.data?.user?._id }));
    }

    dispatch(signupSuccess());

    if (currentPlatform == "android" || currentPlatform == "ios") {
      pushPixel(`web_signup_success_${currentPlatform}`, {
        Page_Url: window.location.href,
      });
    } else {
      pushPixel("web_signup_success", {
        Page_Url: window.location.href,
      });
    }

    GA.userScope({
      user_id: res.data?.data?.user?._id,
      user_status: res.data?.data?.user?.userStatus,
      hubspot_id: res.data?.data?.user?.hubspotContactId,
    });
    GA.setUserData({
      sha256_email_address: await hash(res.data?.data?.user?.emailId),
      sha256_phone_number: await hash(res.data?.data?.user?.phoneNumber),
      address: {
        "address.sha256_first_name": await hash(res.data?.data?.user?.name),
        "address.sha256_last_name": "",
        city: "",
        region: "",
        postal_code: await hash(res.data?.data?.user?.pincode),
        country: await hash("India"),
      },
    });
    console.log("hash", await hash(res.data?.data?.user?.emailId));
    GA.event({
      action: "web_phone_login",
      params: {
        Page_Url: window.location.href,
      },
    });
    GA.setUserData({
      sha256_email_address: await hash(res.data?.data?.user?.emailId),
      sha256_phone_number: await hash(res.data?.data?.user?.phoneNumber),
      address: {
        "address.sha256_first_name": await hash(res.data?.data?.user?.name),
        "address.sha256_last_name": "",
        city: "",
        region: "",
        postal_code: await hash(res.data?.data?.user?.pincode),
        country: await hash("India"),
      },
    });

    return res?.data;
  }
);

export const emailLogin: any = createAsyncThunk(
  "auth/emailLogin",
  async (
    { email, phone, password }: any,
    { rejectWithValue, dispatch }: any
  ) => {
    const payload = {
      emailId: email,
      phoneNo: phone,
      md5Password: md5(password),
    };

    const res = await authenticatedAxios
      .post("/api/v1/internal/login", payload)
      .catch((error: any) => {
        console.log("Login w/ OTP", error);

        throw rejectWithValue(error?.response?.data || error?.data);
      });

    if (res.status === 200) {
      dispatch(getOnboarding({ userId: res.data?.data?.user?._id }));
    }

    dispatch(setShowLogin(false));
    dispatch(clearSendOTP());
    window?.Moengage?.add_unique_user_id(
      res?.data.data?.user?.hubspotContactId
    );
    window?.Moengage?.add_first_name(res?.data.data?.user?.name.split(" ")[0]);
    window?.Moengage?.add_last_name(
      res?.data.data?.user?.name.split(" ").slice(1).join(" ")
    );
    window?.Moengage?.add_mobile(res?.data.data?.user?.phoneNo);
    window?.Moengage?.add_email(res?.data.data?.user?.emailId);
    Moengage.pushEvent("web_login_success", {
      hubspotId: res.data?.data?.user?.hubspotContactId,
      email: res.data?.data?.user?.emailId,
      name: res.data?.data?.user?.name,
      mobileNum: res.data?.data?.user?.phoneNo,
      age: res.data?.data?.user?.age,
      gender: res.data?.data?.user?.gender,
      userStatus: res.data?.data?.user?.userStatus,
      userCategory: res.data?.data?.user?.category,
      pincode: res.data?.data?.user?.pincode,
    });

    GA.userScope({
      user_id: res.data?.data?.user?._id,
      user_status: res.data?.data?.user?.userStatus,
      hubspot_id: res.data?.data?.user?.hubspotContactId,
    });
    GA.setUserData({
      sha256_email_address: await hash(res.data?.data?.user?.emailId),
      sha256_phone_number: await hash(res.data?.data?.user?.phoneNumber),
      address: {
        "address.sha256_first_name": await hash(res.data?.data?.user?.name),
        "address.sha256_last_name": "",
        city: "",
        region: "",
        postal_code: await hash(res.data?.data?.user?.pincode),
        country: await hash("India"),
      },
    });

    GA.event({
      action: "web_email_login",
      params: {
        Page_Url: window.location.href,
      },
    });

    return res?.data;
  }
);

export const autoLogin: any = createAsyncThunk(
  "auth/autoLogin",
  async (
    { email, phone, password }: any,
    { rejectWithValue, dispatch }: any
  ) => {
    const payload = {
      emailId: email,
      phoneNo: phone,
      md5Password: password,
    };

    const res = await authenticatedAxios
      .post("/api/v1/internal/autologin", payload)
      .catch((error: any) => {
        console.log("Login w/ OTP", error);

        throw rejectWithValue(error?.response?.data || error?.data);
      });

    if (res.status === 200) {
      dispatch(getOnboarding({ userId: res.data?.data?.user?._id }));
    }

    dispatch(setShowLogin(false));
    dispatch(clearSendOTP());
    window?.Moengage?.add_unique_user_id(
      res?.data.data?.user?.hubspotContactId
    );
    window?.Moengage?.add_first_name(res?.data.data?.user?.name.split(" ")[0]);
    window?.Moengage?.add_last_name(
      res?.data.data?.user?.name.split(" ").slice(1).join(" ")
    );
    window?.Moengage?.add_mobile(res?.data.data?.user?.phoneNo);
    window?.Moengage?.add_email(res?.data.data?.user?.emailId);
    Moengage.pushEvent("web_login_success", {
      hubspotId: res.data?.data?.user?.hubspotContactId,
      email: res.data?.data?.user?.emailId,
      name: res.data?.data?.user?.name,
      mobileNum: res.data?.data?.user?.phoneNo,
      age: res.data?.data?.user?.age,
      gender: res.data?.data?.user?.gender,
      userStatus: res.data?.data?.user?.userStatus,
      userCategory: res.data?.data?.user?.category,
      pincode: res.data?.data?.user?.pincode,
    });

    GA.userScope({
      user_id: res.data?.data?.user?._id,
      user_status: res.data?.data?.user?.userStatus,
      hubspot_id: res.data?.data?.user?.hubspotContactId,
    });
    GA.setUserData({
      sha256_email_address: await hash(res.data?.data?.user?.emailId),
      sha256_phone_number: await hash(res.data?.data?.user?.phoneNumber),
      address: {
        "address.sha256_first_name": await hash(res.data?.data?.user?.name),
        "address.sha256_last_name": "",
        city: "",
        region: "",
        postal_code: await hash(res.data?.data?.user?.pincode),
        country: await hash("India"),
      },
    });

    GA.event({
      action: "web_auto_login",
      params: {
        Page_Url: window.location.href,
      },
    });

    return res?.data;
  }
);

export const getUserPlan: any = createAsyncThunk(
  "auth/getUserPlan",
  async (payload: any) => {
    const { userId, pageNo, pageSize } = payload;

    authenticatedAxios
      .post(
        `/api/v1/user/${userId}/allUserPlans?pageNo=${pageNo}&pageSize=${pageSize}`
      )
      .then((response) => {
        console.log(response);
        return response.data.data;
      })
      .catch((error) => {
        console.log("Fetch all active plans List -> Failure", error);
      });
  }
);
