import { useEffect, useLayoutEffect } from "react";

import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { appWithTranslation } from "next-i18next";

import Cookies from "js-cookie";

import { Provider } from "react-redux";

import ThemeProvider from "../shared/styles/theme";
import CacheBuster from "../shared/components/CacheBuster";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../store";
import { setViewport } from "../slices/viewport.slice";

import GlobalStyles from "./../shared/styles/globals";
import { useHasMounted, useUTMCapture } from "./../shared/hooks";

import { setUTM } from "./../slices/utm.slice";
import { useMountEffect } from "./../shared/hooks";
import { ErrorBoundaryWrapper } from "../shared/components/Error/ErrorBoundary";

// Marketing Campaigns & Analytics
import GTagScript from "../shared/scripts/GTagScript";

const HotjarScript = dynamic(() => import("../shared/scripts/HotjarScript"), {
  ssr: false,
});
const FacebookPixelScript = dynamic(
  () => import("../shared/scripts/FacebookPixelScript"),
  {
    ssr: false,
  }
);
const InMobiScript = dynamic(() => import("../shared/scripts/InMobi"), {
  ssr: false,
});
const MoEngageScript = dynamic(
  () => import("../shared/scripts/MoEngageScript"),
  {
    ssr: false,
  }
);

const LoginDialog = dynamic(() => import("./../components/Login/index"), {});

declare const window: any;

const captureViewport = () => {
  window?.store?.dispatch(
    setViewport({
      width: window?.innerWidth,
      height: window?.innerHeight,
    })
  );
};

function App({ Component, pageProps }: AppProps<any>) {
  const router = useRouter();
  const mounted = useHasMounted();
  const utm: { [key: string]: string } = useUTMCapture() || {};

  useMountEffect(() => {
    window.store = store;
    window.persistor = persistor;

    captureViewport();
  });

  useEffect(() => {
    if (!Object.keys(utm)?.length) return;

    store.dispatch(setUTM(utm));
    store.dispatch({
      type: "utmReducer/setUTM",
      payload: utm || {},
    });

    for (const key of Object.keys(utm)) {
      Cookies.set(key, utm[key], { expires: 1 });
    }
  }, [utm]);

  useLayoutEffect(() => {
    window.addEventListener("resize", captureViewport);

    return () => window.removeEventListener("resize", captureViewport);
  }, []);

  return (
    <>
      <GlobalStyles />
      <MoEngageScript />

      <CacheBuster>
        <Provider store={store}>
          <ThemeProvider>
            <>
              {!mounted && <Component {...pageProps} />}

              {mounted && (
                <PersistGate
                  loading={<Component {...pageProps} />}
                  persistor={persistor}
                >
                  <ErrorBoundaryWrapper>
                    <LoginDialog />
                    <Component {...pageProps} key={router.pathname} />
                  </ErrorBoundaryWrapper>
                </PersistGate>
              )}
            </>
          </ThemeProvider>

          {mounted && (
            <>
              {/* <OutbrainScriptPixel /> */}
              {/* <OutBrainCallbackScript /> */}
            </>
          )}
        </Provider>
      </CacheBuster>

      {/* Marketing Campaign Pixels & Analytics Scripts*/}
      <GTagScript />
      <HotjarScript />
      {/* <ZendeskScript /> */}
      <FacebookPixelScript />

      {mounted && (
        <>
          {(router.query.utm_source === "InMobi" ||
            Cookies.get("iDSP_Cookie")) && <InMobiScript />}
        </>
      )}
    </>
  );
}

export default appWithTranslation(App);
