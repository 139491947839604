import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { authenticatedAxios } from "../../shared/setups/axios";
import Moengage from "../../shared/utils/moengage.utils";

import { signupSuccess } from "../tracker.slice";
import { useGlobalDispatch } from "./../../shared/hooks/useGlobalDispatch";
import GA from "./../../shared/utils/ga.utils";
import { hash } from "../../shared/utils/helper.utils";
import { getOnboarding } from "./login";
import { pushPixel } from "../../shared/utils/tracker.utils";

export const fetchIpAddress: any = createAsyncThunk(
  "auth/fetchIpAddress",
  async () => {
    return axios.get("https://api.ipify.org/?format=json").then(({ data }) => {
      return data.ip;
    });
  }
);

export const preSignup: any = createAsyncThunk(
  "auth/preSignup",
  async (payload: any) => {
    const reqPayload = {
      client_id: payload.client_id,
      fullName: payload.fullName,
      emailId: payload.emailId,
      phoneNo: payload.phoneNo,
      whatsappOptedIn: payload.whatsappOptedIn,
      pincode: payload.pincode,
      userPreferredLanguage: payload.language,
      ipAddress: payload.ipAddress,
      huspotId: payload.huspotId,
      platformPlusTimestamp: payload.platformPlusTimestamp,
      apiConsumerInfo: payload.apiConsumerInfo,
      utm_campaign: payload.utmCampaign,
      utm_medium: payload.utmMedium,
      utm_source: payload.utmSource,
    };

    await authenticatedAxios
      .post("/api/v2/internal/preSignup", reqPayload)
      .then((response) => {
        if (response.data.code === 200) {
          if (response.data.data) {
            return response.data.data;
          }
        }
      });
  }
);

export const verifySignupPayload: any = createAsyncThunk(
  "auth/verifySignupPayload",
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      return await authenticatedAxios
        .post("/api/v1/internal/signupPayloadVerify", payload)
        .then((response: any) => {
          if (response.data.code === 200) {
            dispatch(signupSuccess());
            return {
              userDetails: payload,
              responseForValidation: response.data.data,
              type: "INTERNAL",
              feedBackMsg: "Internal Otp Sent",
            };
          }
        });
    } catch (err: any) {
      return rejectWithValue(err.data.errMsg);
    }
  }
);

export const verifyOtp: any = createAsyncThunk(
  "auth/verifyOtp",
  async (payload: any, { rejectWithValue }) => {
    console.log("clicked payload...", payload);

    const dispatchAuth = useGlobalDispatch("fe-js", "authReducer");

    try {
      return await authenticatedAxios
        .post("/api/v1/internal/signup", payload)
        .then((response) => {
          if (response.data.code == 200) {
            console.log("Response Data", response.data);

            dispatchAuth({
              type: "REPLACE",
              payload: {
                userInfo: {
                  userId: response.data.data.userId,
                  accessToken: response.data.data.accessToken,
                  refreshToken: response.data.data.refreshToken,
                  user: {
                    ...response?.data?.data?.user,
                  },
                },
              },
            });

            window?.Moengage?.add_unique_user_id(
              response.data?.data?.user?.hubspotContactId
            );
            window?.Moengage?.add_unique_user_id(
              response?.data.data?.user?.hubspotContactId
            );
            window?.Moengage?.add_first_name(
              response?.data.data?.user?.name.split(" ")[0]
            );
            window?.Moengage?.add_last_name(
              response?.data.data?.user?.name.split(" ").slice(1).join(" ")
            );
            window?.Moengage?.add_mobile(response?.data.data?.user?.phoneNo);
            window?.Moengage?.add_email(response?.data.data?.user?.emailId);
            Moengage.pushEvent("web_signup_success", {
              hubspotId: response.data?.data?.user?.hubspotContactId,
              name: response.data?.data?.user?.name,
              email: response.data?.data?.user?.emailId,
              phoneNo: response.data?.data?.user?.phoneNo,
              pincode: response.data?.data?.user?.pincode,
              userStatus: response.data?.data?.user?.userStatus,
              category: response.data?.data?.user?.category,
            });

            GA.userScope({
              user_id: response.data?.data?.user?._id,
              user_status: response.data?.data?.user?.userStatus,
              hubspot_id: response.data?.data?.user?.hubspotContactId,
            });

            GA.event({
              action: "web_signup_success",
              params: {
                Page_Url: window.location.href,
              },
            });
            pushPixel("web_signup_success", {
              Page_Url: window.location.href,
            });
            GA.event({
              action: "sign_up",
              params: {
                Page_Url: window.location.href,
              },
            });

            return {
              isError: false,
              userInfo: response.data.data,
            };
          }
        });
    } catch (err: any) {
      return rejectWithValue(err.data.errMsg);
    }
  }
);

export const fetchUserDetails: any = createAsyncThunk(
  "auth/fetchUser",
  async ({ userId }: any, { rejectWithValue, dispatch }: any) => {
    const res: any = await authenticatedAxios({
      url: `/api/v1/user/findByID/${userId}`,
    }).catch((error) => {
      console.error("error", `/api/v1/user/findByID/${userId}`, error);

      if (res.status === 200) {
        dispatch(getOnboarding({ userId: res.data?.data?.user?._id }));
      }

      return rejectWithValue(error.data.errMsg);
    });

    return res?.data;
  }
);

// Signup
export const updateLead: any = createAsyncThunk(
  "auth/updateLead",
  async (
    { calledAt, payload, leadId }: any,
    { rejectWithValue, dispatch }: any
  ) => {
    try {
      const response = await authenticatedAxios.post(
        `api/v2/internal/updateLead/${leadId}`,
        payload
      );

      window?.Moengage?.add_unique_user_id(
        response.data?.data?.user?.hubspotContactId
      );
      window?.Moengage?.add_unique_user_id(
        response?.data.data?.user?.hubspotContactId
      );
      window?.Moengage?.add_first_name(
        response?.data.data?.user?.name.split(" ")[0]
      );
      window?.Moengage?.add_last_name(
        response?.data.data?.user?.name.split(" ").slice(1).join(" ")
      );
      window?.Moengage?.add_mobile(response?.data.data?.user?.phoneNo);
      window?.Moengage?.add_email(response?.data.data?.user?.emailId);
      Moengage.pushEvent("web_signup_success", {
        hubspotId: response.data?.data?.user?.hubspotContactId,
        name: response.data?.data?.user?.name,
        email: response.data?.data?.user?.emailId,
        phoneNo: response.data?.data?.user?.phoneNo,
        pincode: response.data?.data?.user?.pincode,
        userStatus: response.data?.data?.user?.userStatus,
        category: response.data?.data?.user?.category,
        utm_source: payload?.utm_source,
      });

      GA.userScope({
        user_id: response.data?.data?.user?._id,
        user_status: response.data?.data?.user?.userStatus,
        hubspot_id: response.data?.data?.user?.hubspotContactId,
      });

      GA.setUserData({
        sha256_email_address: await hash(response.data?.data?.user?.emailId),
        sha256_phone_number: await hash(response.data?.data?.user?.phoneNumber),
        address: {
          "address.sha256_first_name": await hash(
            response.data?.data?.user?.name
          ),
          "address.sha256_last_name": "",
          city: "",
          region: "",
          postal_code: await hash(response.data?.data?.user?.pincode),
          country: await hash("India"),
        },
      });
      GA.event({
        action: "web_signup_success",
        params: {
          Page_Url: window.location.href,
        },
      });
      pushPixel("web_signup_success", {
        Page_Url: window.location.href,
      });
      GA.event({
        action: "sign_up",
        params: {
          Page_Url: window.location.href,
        },
      });

      if (response.status === 200) {
        dispatch(getOnboarding({ userId: response.data?.data?.user?._id }));
      }

      return response?.data;
    } catch (error: any) {
      if (typeof leadId === "undefined") {
        GA.event({
          action: "updateLead_leadId_undefined",
          params: {
            event_category: "API Call",
            name: payload.name,
            emailId: payload.email,
            phoneNo: payload.phoneNo,
            respId: error.data.respId,
            errorMessage: error.data.errMsg,
          },
        });
      }
      GA.event({
        action: "web_signup_failed",
        params: {
          event_category: "API Call",
          name: payload.name,
          emailId: payload.email,
          phoneNo: payload.phoneNo,
          respId: error.data.respId,
          errorMessage: error.data.errMsg,
          location: calledAt,
        },
      });
      console.log(error.data);
      return rejectWithValue(error.data);
    }
  }
);

export const updateUserDoB: any = createAsyncThunk(
  "auth/updateUserDoB",
  async ({ payload, userId }: any, { rejectWithValue, dispatch }: any) => {
    const res: any = await authenticatedAxios
      .post("/api/v1/user/updateDob", {
        userId,
        ...payload,
      })
      .catch((error) => {
        console.error("error", `/api/v1/user/findByID/${userId}`, error);

        if (res.status === 200) {
          dispatch(getOnboarding({ userId: res.data?.data?.user?._id }));
          dispatch(fetchUserDetails({ userId: res.data?.data?.user?._id }));
        }

        return rejectWithValue(error.data.errMsg);
      });

    return res?.data;
  }
);
