import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type DoctorInitialDetailsType = {
  name: string;
  specialty: string[];
  location: string;
};

const doctorInitialDetails: DoctorInitialDetailsType = {
  name: "",
  specialty: [],
  location: "",
};

const doctorSlice = createSlice({
  name: "doctor",
  initialState: doctorInitialDetails,
  reducers: {
    setSpecialties: (state, action: PayloadAction<{ specialty: string[] }>) => {
      state.specialty = action.payload.specialty;
    },
    setLocation: (state, action: PayloadAction<{ location: string }>) => {
      state.location = action.payload.location;
    },
    setDoctorName: (state, action: PayloadAction<{ doctorName: string }>) => {
      state.name = action.payload.doctorName;
    },
    resetSpecialties: (state) => {
      state.specialty = [];
    },
  },
});

export const { setSpecialties, setLocation, setDoctorName, resetSpecialties } =
  doctorSlice.actions;
export default doctorSlice.reducer;
