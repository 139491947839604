export const calculatePlanPrice = (dependents: any, selectedTenure: any) => {
  // Calculate Plan Prices whenever dependents change
  const pricing = selectedTenure?.basePlanUIMetaData?.dependentMetaData;

  let price = pricing?.SELF?.costPerHead;
  price += dependents.spouse ? pricing?.SPOUSE?.costPerHead : 0;
  price += dependents.children * pricing?.CHILD?.costPerHead;

  return price;
};

const SUCCESS_STATUS = ["CHARGED"];
const FAILURE_STATUS = [
  "AUTHENTICATION_FAILED",
  "AUTHORIZATION_FAILED",
  "JUSPAY_DECLINED",
  "AUTO_REFUNDED",
  "CAPTURE_FAILED",
  "NOT_FOUND",
];
const PENDING_STATUS = [
  "NEW",
  "PENDING_VBV",
  "VBV_SUCCESSFULL",
  "AUTHORIZING",
  "STARTED",
  "CAPTURE_INITIATED",
  // "VOID_INITIATED",
  // "VOIDED",
  // "VOID_FAILED",
  "",
];

export const mapPaymentStatus = (status: string) => {
  if (SUCCESS_STATUS.includes(status)) return "SUCCESS";

  if (FAILURE_STATUS.includes(status)) return "FAILURE";

  if (PENDING_STATUS.includes(status)) return "PENDING";

  return null;
};

export const getPlatformType = (platform: string) => {
  const windowWidth = window?.innerWidth;

  if (platform === "android" || platform === "ios") return platform;
  else if (windowWidth < 600) return "mweb";
  else if (windowWidth > 600) return "dweb";
  return "device";
};

export const businessFlow = {
  RETAIL: {
    business_flow: "retail",
    event_type_id: "1",
  },
};

export const getBackendEventPlatformType = (platform: string) => {
  const windowWidth = window?.innerWidth;

  if (platform === "android" || platform === "ios")
    return `IWEB_${platform.toUpperCase()}`;
  else if (windowWidth < 600) return "MWEB";
  else if (windowWidth > 600) return "DWEB";
  return "device";
};

export const detectMobileApp = (platform: string) =>
  platform === "android" || platform === "ios" ? true : false;

// Get price formatted with comma
export const getFormattedPrice = (price: number) =>
  new Intl.NumberFormat("en-IN").format(price);

export const openWhatsappForReferrals = (link: string) => {
  try {
    // @ts-ignore
    AndroidAction?.handleURLOnApplication(link);
    return;
  } catch (error) {
    console.log("Android", error);
  }

  try {
    // @ts-ignore
    handleURLOnApplication(link);
    return;
  } catch (error) {
    console.log("iOS", error);
  }
};
