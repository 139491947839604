import { combineReducers } from "@reduxjs/toolkit";

import utmReducer from "./utm.slice";
import hospitalReducer from "./hospital.slice";
import quizReducer from "./quiz.slice";
import authReducer from "./auth-slice";
import assetsReducer from "./assets.slice";
import trackerReducer from "./tracker.slice";
import cartReducer from "./cart.slice";
import paymentReducer from "./payment.slice";
import userPlansReducer from "./userPlans.slice";
import bankReducer from "./bank.slice";
import viewportReducer from "./viewport.slice";
import onboardingReducer from "./onboarding.slice";
import doctorReducer from "./doctor.slice";

export const rootReducer = combineReducers({
  auth: authReducer,
  assets: assetsReducer,
  bank: bankReducer,
  hospital: hospitalReducer,
  utmReducer,
  quiz: quizReducer,
  tracker: trackerReducer,
  cart: cartReducer,
  payment: paymentReducer,
  userPlans: userPlansReducer,
  viewport: viewportReducer,
  onboarding: onboardingReducer,
  doctor: doctorReducer,
});

export default rootReducer;
