import { useState } from "react";
import styled from "@emotion/styled";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { DownChevron } from "../../../icons";

import { useHasMounted } from "../../../hooks";

const DropLinksWrapper = styled.div<any>`
  position: relative;

  a {
    text-decoration: none;
    color: var(--kenkoDeepTeal);
  }

  .drop-links {
    position: relative;

    display: flex;
    justify-content: ${(props) => props.align || "center"};
    align-items: center;

    gap: 10px;

    cursor: pointer;
    user-select: none;

    .drop-links-button {
      font-weight: 600;
      color: var(--kenkoDeepTeal);
    }

    .drop-links-links {
      position: absolute;
      top: 150%;

      left: ${(props) => props.popupLeft || "-50%"};
      right: ${(props) => props.popupRight};
      min-width: ${(props) => props.popupWidth || "180px"};

      @media (max-width: 600px) {
        left: 0;
      }

      background: var(--kenkoPureWhite);
      color: var(--kenkoDeepTeal);

      border-radius: 10px;

      display: flex;
      flex-direction: column;
      // gap: 10px;

      overflow: hidden;

      .drop-links-link {
        font-weight: 600;
        padding: 10px 20px;

        display: flex;
        align-items: center;
        gap: 10px;

        min-height: 45px;

        &:hover {
          background: var(--mossGreen100);
        }
      }
    }

    .chevron {
      margin-top: -5px;
    }

    .chevron-down {
      transform: rotate(180deg);
      -webkit-transform-origin-y: 14px;
    }
  }
`;

const DropLinks = ({
  button,
  links,
  align,
  popupLeft,
  popupRight,
  popupWidth,
  popup,
}: any) => {
  const [open, setOpen] = useState(false);

  const mounted = useHasMounted();

  if (mounted) {
    return (
      <DropLinksWrapper
        popupLeft={popupLeft}
        popupRight={popupRight}
        popupWidth={popupWidth}
        align={align}
      >
        <div className="drop-links" onClick={() => setOpen(!open)}>
          <div className="drop-links-button">{button}</div>
          <>
            {open && (
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                {popup || (
                  <div className="drop-links-links">
                    {links.map((link: any, index: number) => (
                      <a
                        key={"link-" + index}
                        href={link.url || "#"}
                        onClick={link?.action}
                      >
                        <div className="drop-links-link">
                          <span>{link.icon}</span>
                          <span>{link.text}</span>
                        </div>
                      </a>
                    ))}
                  </div>
                )}
              </ClickAwayListener>
            )}
          </>

          <div
            className={["chevron", `${open ? "chevron-down" : ""}`].join(" ")}
          >
            <DownChevron />
          </div>
        </div>
      </DropLinksWrapper>
    );
  } else {
    return (
      <DropLinksWrapper>
        <div className="drop-links">
          <span className="drop-links-button">{button}</span>
          <span className={["chevron"].join(" ")}>
            <DownChevron />
          </span>
        </div>
      </DropLinksWrapper>
    );
  }
};

export default DropLinks;
