import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// createSlice : API function to define logic , PayloadAction: Represent content of a single Action

interface IViewportState {
  width: number;
  height: number;
}

const initialState: IViewportState = {
  width: 0,
  height: 0,
};

const viewportSlice = createSlice({
  name: "tracker",
  initialState,
  reducers: {
    setViewport: (state: any, action: PayloadAction<any>) => {
      return {
        width: action?.payload?.width,
        height: action?.payload?.height,
      };
    },

    clearViewport: () => {
      return initialState;
    },
  },
});

export const { clearViewport, setViewport } = viewportSlice.actions;

export default viewportSlice.reducer;
