import styled from "@emotion/styled";

const WrapperItemStyles = styled.div<any>`
  position: {props => props.position && relative};

  box-sizing: border-box;
  
  align-self: ${(props) => props.align};

  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  background: ${(props) => props.background};
  color: ${(props) => props.color};

  grow: ${(props) => props.grow};
  shrink: ${(props) => props.shrink};
  basis: ${(props) => props.basis};
  flex: ${(props) => props.flex};

  position: ${(props) => props.pop && "absolute"};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};

  cursor: ${(props) => props.cursor && "pointer"};
`;

const WrapperItem = ({
  align,
  padding,
  margin,
  height,
  width,
  background,
  color,
  children,

  grow,
  shrink,
  basis,
  flex,

  pop,
  left,
  right,
  bottom,
  top,

  className,
  onClick,
}: any) => {
  const getAlignment = () => {
    if (align === "center") {
      return "center";
    }

    if (align === "top") {
      return "flex-start";
    }

    if (align === "bottom") {
      return "flex-end";
    }
  };

  return (
    <WrapperItemStyles
      align={getAlignment()}
      padding={padding}
      margin={margin}
      height={height}
      width={width}
      background={background}
      color={color}
      flex={flex}
      grow={grow}
      shrink={shrink}
      basis={basis}
      pop={pop}
      left={left}
      right={right}
      top={top}
      bottom={bottom}
      className={className}
      onClick={onClick}
    >
      {children}
    </WrapperItemStyles>
  );
};

export default WrapperItem;
