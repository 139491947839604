import styled from "@emotion/styled";

const WrapperStyle = styled.div<any>`
  position: ${(props) => props.position};
  display: ${(props) => !props.disableFlex && "flex"};
  flex-direction: ${(props) => props.flexDirection};

  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  gap: ${(props) => props.spacing};

  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  height: ${(props) => props.height || "fit-content"};
  width: ${(props) => props.width};
  border: ${(props) => props.border};
  background: ${(props) => props.background};
  color: ${(props) => props.color};

  flex: ${(props) => props.flex};
  flex-shrink: ${(props) => props.shrink};
  flex-grow: ${(props) => props.grow};
  flex-basis: ${(props) => props.basis};

  top: ${(props) => props.top};
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};

  flex-wrap: ${(props) => props.wrap};
`;

const Wrapper = ({
  className,
  children,

  justify,
  align,
  alignSelf,
  padding,
  margin,
  height,
  width,
  background,
  color,
  border,
  spacing,

  flex,
  grow,
  shrink,
  basis,

  container,
  pop,
  left,
  right,
  top,
  bottom,

  disableFlex,
  flexDirection,
  wrap,
}: any) => {
  const getJustification = () => {
    if (justify === "center") {
      return "center";
    }

    if (justify === "left") {
      return "flex-start";
    }

    if (justify === "right") {
      return "flex-end";
    }

    if (justify === "even") {
      return "space-evenly";
    }

    if (justify === "space-around") {
      return "space-around";
    }

    if (justify === "space-between") {
      return "space-between";
    }
  };

  const getAlignment = () => {
    if (align === "center") {
      return "center";
    }

    if (align === "top") {
      return "flex-start";
    }

    if (align === "bottom") {
      return "flex-end";
    }
  };

  const getPosition = () => {
    if (container) return "relative";

    if (pop) return "absolute";
  };

  return (
    <WrapperStyle
      className={className}
      justify={getJustification()}
      align={getAlignment()}
      alignSelf={alignSelf}
      position={getPosition()}
      padding={padding}
      margin={margin}
      height={height}
      width={width}
      background={background}
      color={color}
      border={border}
      spacing={spacing}
      flex={flex}
      grow={grow}
      shrink={shrink}
      basis={basis}
      left={left}
      right={right}
      top={top}
      bottom={bottom}
      disableFlex={disableFlex || false}
      flexDirection={flexDirection}
      wrap={wrap}
    >
      {children}
    </WrapperStyle>
  );
};

export default Wrapper;
