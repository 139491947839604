export const getuserAddress = () => {
  const a = navigator.platform + ":" + navigator.vendor + ":" + Date.now();
  console.log("Unique " + a);
  return a;
};

export const shouldRedirectToNewFlow = (state: any) => {
  console.log(state);

  return true;

  // Switch on new plans based on utms
  // /foreverplan/gi?.test(state?.c || state?.utm_campaign);
};

export const hash = (string: string) => {
  const utf8 = new TextEncoder().encode(string);
  return window?.crypto.subtle.digest("SHA-256", utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  });
};
