import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { fetchUTMAssets } from "../../slices/assets.slice";

const useFetchUtmAssets = () => {
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(
      fetchUTMAssets({
        utmCampaign: "App | In-app action | Sign up | ForeverPlan | Android",
      })
    );
  }, []);
};

export default useFetchUtmAssets;
