import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useViewport = (query: string | number) => {
  const { width } = useSelector((state: any) => state.viewport);

  return useMemo(() => {
    if (width === 0)
      return {
        above: false,
        below: false,
      };

    const _width = width as number;
    const _query = query as number;

    return {
      above: _width > _query,
      below: _width < _query,
    };
  }, [query, width]);
};

export default useViewport;
