import { IconProps } from "../types";

const KenkoLogo = ({ width, height, color, className, onClick }: IconProps) => {
  return (
    <>
      <svg
        className={className}
        width={width || 127}
        height={height || 30}
        onClick={onClick}
        viewBox="0 0 127 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40.186 28.3937C41.9479 28.3937 43.3691 26.9052 43.3691 25.1433C43.3691 22.9841 43.4374 21.8733 43.4374 21.9162L34.4553 21.9046V17.8306L39.3956 17.7893C41.1442 17.7747 42.5513 16.3202 42.5513 14.5715C42.5513 12.3987 42.587 11.2423 42.587 11.2444L34.4553 11.3123V7.36061L40.186 7.36803C41.9471 7.37031 43.3691 5.883 43.3691 4.12183C43.3691 1.96265 43.4374 0.894684 43.4374 0.894684L30.4074 0.883057C27.7087 0.883057 26.4277 0.894684 26.4277 0.894684L26.3594 24.8378C26.3594 27.2007 26.4277 28.3937 26.4277 28.3937H40.186Z"
          fill={color || "#003032"}
        />
        <path
          d="M19.5965 28.3938C20.5506 28.3938 21.4438 28.0212 22.2761 27.276C23.1085 26.5307 23.5246 25.7178 23.5246 24.837C23.5246 23.9563 23.051 22.9875 22.1036 21.9306L15.4856 14.6138L22.1036 7.33764C23.0374 6.30787 23.5043 5.34584 23.5043 4.45156C23.5043 3.55728 23.0882 2.74092 22.2558 2.00246C21.4235 1.264 20.5371 0.894775 19.5965 0.894775C18.6559 0.894775 17.6645 1.45709 16.6224 2.58171L8.05551 14.6846L8.04388 4.99161C8.0412 2.76414 6.26344 0.955749 4.03597 0.955749C1.35628 0.955749 0.0164339 0.98831 0.0164339 0.98831L0.0024202 24.3579C0.00108379 26.5865 1.80736 28.3938 4.03597 28.3938C6.71566 28.3938 8.03907 28.3938 8.03907 28.3938L8.05551 14.5836L16.6224 26.7272C17.651 27.8383 18.6423 28.3938 19.5965 28.3938Z"
          fill={color || "#003032"}
        />
        <path
          d="M93.3054 28.3938C94.2596 28.3938 95.1528 28.0212 95.9851 27.276C96.8175 26.5307 97.2336 25.7178 97.2336 24.837C97.2336 23.9563 96.7599 22.9875 95.8126 21.9306L89.1945 14.6138L95.8126 7.33764C96.7464 6.30787 97.2133 5.34584 97.2133 4.45156C97.2133 3.55728 96.7972 2.74092 95.9648 2.00246C95.1325 1.264 94.246 0.894775 93.3054 0.894775C92.3648 0.894775 91.3735 1.45709 90.3314 2.58171L81.7645 14.6846L81.7529 4.99161C81.7502 2.76414 79.9724 0.955749 77.745 0.955749C75.0653 0.955749 73.7254 0.98831 73.7254 0.98831L73.7114 24.3579C73.7101 26.5865 75.5163 28.3938 77.745 28.3938C80.4246 28.3938 81.7481 28.3938 81.7481 28.3938L81.7645 14.5836L90.3314 26.7272C91.36 27.8383 92.3513 28.3938 93.3054 28.3938Z"
          fill={color || "#003032"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M122.734 25.4143C119.89 28.1271 116.289 29.4835 111.932 29.4835C107.575 29.4835 103.989 28.1271 101.173 25.4143C98.3572 22.7015 96.9492 19.151 96.9492 14.7628C96.9492 10.3746 98.3502 6.8171 101.152 4.09026C103.954 1.36342 107.565 0 111.985 0C116.405 0 120.012 1.36342 122.807 4.09026C125.602 6.8171 127 10.3746 127 14.7628C127 19.151 125.578 22.7015 122.734 25.4143ZM106.218 20.724C107.673 22.2707 109.595 23.044 111.985 23.044C114.375 23.044 116.294 22.2628 117.741 20.7003C119.188 19.1379 119.911 17.1652 119.911 14.7821C119.911 12.399 119.192 10.4263 117.753 8.86386C116.314 7.30145 114.395 6.52024 111.997 6.52024C109.599 6.52024 107.673 7.3054 106.218 8.8757C104.763 10.446 104.035 12.4266 104.035 14.8176C104.035 17.2086 104.763 19.1774 106.218 20.724Z"
          fill={color || "#003032"}
        />
        <path
          d="M66.8081 28.3526C69.4969 28.3526 70.8413 27.1711 70.8413 24.8083L70.8672 0.895054C70.8672 0.895054 69.4969 0.853768 66.8081 0.853768C65.0204 0.853768 63.6489 2.26458 63.6456 4.05233L63.6255 15.1007L54.696 3.15457C53.6262 1.72335 51.9734 0.839699 50.1866 0.853768C47.4979 0.867348 46.2031 0.895054 46.2031 0.895054V28.3941C46.2031 28.3941 47.4979 28.3526 50.1866 28.3526C52.8754 28.3526 53.3693 27.1711 53.3693 24.8083L53.419 28.3941L53.3693 12.931L64.1143 26.8045C64.9834 27.8365 65.3144 28.3526 66.8081 28.3526Z"
          fill={color || "#003032"}
        />
      </svg>
    </>
  );
};

export default KenkoLogo;
