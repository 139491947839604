import axios from "axios";
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 } from "uuid";

const baseURL = process.env.NEXT_PUBLIC_BASE_URL;

const paymentURL = process.env.NEXT_PUBLIC_BASE_PAYMENTS_URL;
const tokenUrl = `${baseURL}/api/v1/general/refreshToken`;

export const authenticatedAxios = axios.create({ baseURL });
export const authPaymentAxios = axios.create({ baseURL: paymentURL });

declare const window: any;

// Add a request interceptor
authenticatedAxios.interceptors.request.use(
  (config) => {
    const authState = window?.store?.getState().auth;
    const accessToken = authState.userInfo && authState.userInfo.accessToken;
    console.log(accessToken, "accessToken");
    if (accessToken) {
      // @ts-ignore
      config.headers.Authorization = "Bearer " + accessToken;
    }

    // @ts-ignore
    config.headers["X-Request-Id"] = `w-${v4()}`;

    // @ts-ignore
    config.headers["Content-Type"] = "application/json";

    // @ts-ignore
    config.headers["Cache-Control"] = "no-cache";
    return config;
  },
  (error) => {
    Promise.reject(error.response);
  }
);

// Add a response interceptor
authenticatedAxios.interceptors.response.use(
  (response) => {
    return response;
  },

  function (error) {
    const originalRequest = error.config;
    const authState = window.store.getState().auth;
    const refreshToken = authState.userInfo && authState.userInfo.refreshToken;
    const userId = authState.userInfo && authState.userInfo.userId;
    const accessToken = authState.userInfo && authState.userInfo.accessToken;

    if (error.response?.status === 410 && originalRequest.url === tokenUrl) {
      window.location.href = "/";
      return Promise.reject(error.response);
    }

    if (error.response?.status === 410 && !originalRequest._retry) {
      originalRequest._retry = true;
      return authenticatedAxios
        .post(tokenUrl, {
          userId,
          accessToken,
          refreshToken,
        })
        .then((res) => {
          if (res?.data?.code === 200) {
            window.store.dispatch({
              type: "auth/setTokens",
              payload: res.data.data,
            });
            authenticatedAxios.defaults.headers.common.Authorization =
              "Bearer " + res?.data?.data?.refreshToken;
            return authenticatedAxios(originalRequest);
          }
        });
    }
    return Promise.reject(error.response);
  }
);

// Add a request interceptor
authPaymentAxios.interceptors.request.use(
  (config) => {
    const authState = window.store.getState().auth;
    const accessToken = authState.userInfo && authState.userInfo.accessToken;
    if (accessToken) {
      // @ts-ignore
      config.headers.Authorization = "Bearer " + accessToken;
    }

    // @ts-ignore
    config.headers["Content-Type"] = "application/json";
    // @ts-ignore
    config.headers["Cache-Control"] = "no-cache";
    return config;
  },
  (error) => {
    Promise.reject(error.response);
  }
);

// Add a response interceptor

authPaymentAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    const authState = window.store.getState().auth;
    const refreshToken = authState.userInfo && authState.userInfo.refreshToken;
    const userId = authState.userInfo && authState.userInfo.userId;
    const accessToken = authState.userInfo && authState.userInfo.accessToken;

    if (error.response.status === 410 && originalRequest.url === tokenUrl) {
      window.location.href = "/";
      return Promise.reject(error.response);
    }

    if (error.response.status === 410 && !originalRequest._retry) {
      originalRequest._retry = true;
      return authPaymentAxios
        .post(tokenUrl, {
          userId,
          accessToken,
          refreshToken,
        })
        .then((res) => {
          if (res?.data?.code === 200) {
            window.store.dispatch({
              type: "auth/setTokens",
              payload: res.data.data,
            });
            authPaymentAxios.defaults.headers.common.Authorization =
              "Bearer " + res?.data?.data?.refreshToken;
            return authPaymentAxios(originalRequest);
          }
        });
    }
    return Promise.reject(error.response);
  }
);
