import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { authenticatedAxios } from "./../shared/setups/axios";
import axios from "axios";

export const fetchUTMAssets: any = createAsyncThunk(
  "assets/fetchUTMAssets",
  async (
    { utmCampaign }: { utmCampaign: string },
    { rejectWithValue }: any
  ) => {
    const apiPayload = {
      utmName: utmCampaign,
    };

    const res: any = await authenticatedAxios
      .post("/api/v2/marketing/images", apiPayload)
      .catch((error) => {
        console.log("Marketing Images", error);

        throw rejectWithValue(error?.data || error?.response?.data);
      });

    return res?.data;
  }
);

export const fetchMarketingAssets: any = createAsyncThunk(
  "assets/fetchMarketingAssets",
  async () => {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_CMS_BASE_URL}/api/marketing-images`
    );

    return res?.data?.data;
  }
);

export const fetchReferralAssets: any = createAsyncThunk(
  "assets/fetchReferralAssets",
  async () => {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_CMS_BASE_URL}/api/referral-images`
    );

    return res?.data?.data;
  }
);

interface IAssetsState {
  isUtmAssetsLoading: boolean;
  isUtmAssetsError: boolean;
  utmAssets: any;
  utmAssetsError: any;
  marketingAssets: any;
  referralAssets: any;
}

const initialState: IAssetsState = {
  isUtmAssetsLoading: false,
  isUtmAssetsError: false,
  utmAssets: {},
  utmAssetsError: null,
  marketingAssets: [],
  referralAssets: [],
};

const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    clearAssets() {
      return { ...initialState };
    },

    setAsset(state: any, action: PayloadAction<any>) {
      return {
        ...state,
        ...action?.payload,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUTMAssets.pending, () => {
      return {
        ...initialState,
        isLoading: true,
      };
    });

    builder.addCase(
      fetchUTMAssets.fulfilled,
      (state: IAssetsState, action: PayloadAction<any>) => {
        // const d = action?.payload?.data;

        return {
          ...state,
          isLoading: false,
          utmAssets: action?.payload?.data?.images,
        };
      }
    );

    builder.addCase(
      fetchUTMAssets.rejected,
      (state: IAssetsState, action: PayloadAction<any>) => {
        return {
          ...state,
          isUtmAssetsLoading: false,
          isUtmAssetsError: true,
          code: action?.payload?.code,
          respId: action?.payload?.respId,
          error: action?.payload?.errMsg,
        };
      }
    );

    builder.addCase(
      fetchMarketingAssets.fulfilled,
      (state: IAssetsState, action: PayloadAction<any>) => {
        return {
          ...state,
          marketingAssets: action.payload,
        };
      }
    );

    builder.addCase(
      fetchReferralAssets.fulfilled,
      (state: IAssetsState, action: PayloadAction<any>) => {
        return {
          ...state,
          referralAssets: action.payload,
        };
      }
    );
  },
});

export const { clearAssets, setAsset } = assetsSlice.actions;

export default assetsSlice.reducer;
