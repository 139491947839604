interface IconProps {
  number?: number;
  height?: number;
  width?: number;
  color?: string;
}

const NumberedIcon = ({}: IconProps) => {
  return (
    <>
      <svg
        width={36}
        height={36}
        viewBox="0 0 48 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx={24}
          cy="24.917"
          r={23}
          fill="none"
          stroke="#0A757C"
          strokeWidth={2}
        />
      </svg>
    </>
  );
};

export default NumberedIcon;
