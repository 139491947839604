import React from "react";
import { LayoutMinimal } from "./../Layout";
import Error from "./Error";
import { ErrorBoundary } from "react-error-boundary";

const ErrorFallback = ({ error }: any) => {
  return (
    <LayoutMinimal>
      <Error error={error.message} />
    </LayoutMinimal>
  );
};

export const ErrorBoundaryWrapper = ({ children }: any) => {
  return (
    <ErrorBoundary fallbackRender={ErrorFallback}>{children}</ErrorBoundary>
  );
};
