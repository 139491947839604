import { Theme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

import * as colors from "./colors";

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: "#003032",
      // background: "#f6f5eb",
      // font: "#003032",
      // secondaryFont: "#EFEDD8",
      // button: "#FBCE60",
      ...colors,
    },
    secondary: {
      main: "#003032",
    },
  },
  typography: {
    fontFamily: ["Poppins", "Merriweather", "Roboto", "Roboto Slab"].join(","),
  },
});

const ThemeProviderWrapper = ({ children }: any) => {
  return (
    <>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  );
};

export default ThemeProviderWrapper;
