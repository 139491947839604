import { createAsyncThunk } from "@reduxjs/toolkit";
import md5 from "md5";
// import md5 from "md5";

import { authenticatedAxios } from "../../shared/setups/axios";

export const verifyforgotPasswordOTP = createAsyncThunk(
  "auth/forgotPassword/verifyOTP",
  async ({ phone, augmentedHash, otp }: any, { rejectWithValue }: any) => {
    const payload = {
      phoneNo: phone,
      augmentedHash,
      otp,
    };

    const res = await authenticatedAxios
      .post("/api/v1/internal/verifyOtp", payload)
      .catch((error: any) => {
        console.log("Verify OTP", error);

        throw rejectWithValue(error?.response?.data || error?.data);
      });

    return res?.data;
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ userId, password }: any, { rejectWithValue }: any) => {
    const payload = {
      userId: userId,
      newMd5Password: md5(password),
    };

    const res = await authenticatedAxios
      .post("/api/v1/internal/resetPassword", payload)
      .catch((error: any) => {
        console.log("Verify OTP", error);

        throw rejectWithValue(error?.response?.data || error?.data);
      });

    return res?.data;
  }
);
