export const MONTHLY = "MONTHLY";
export const QUARTERLY = "QUARTERLY";
export const SEMIANNUAL = "SEMIANNUAL";
export const ANNUAL = "ANNUAL";

export const plansRoutes = [
  "Starter-Plan",
  "Personal-Plan",
  "The-Family-Plan",
  "Diabetic-Plan",
  "Family-OPD-Plus-Plan",
  "Family-OPD-Prime-Plan",
  "Family-OPD-Standard-Plan",
  "Personal-OPD-Plan",
  "Personal-OPD-Prime-Plan",
  "Personal-OPD-Standard-Plan",
  "Senior-Citizens-Plan",
];

export const visiblePlans = [
  "Family OPD Prime Plan",
  "Personal OPD Prime Plan",
  "The Family Plan",
  "Personal Plan",
];

export const USER_TYPE = {
  B2B: "B2B",
  B2C: "B2C",
};
export const PHONE_LOGIN = "PHONE_LOGIN";
export const EMAIL_LOGIN = "EMAIL_LOGIN";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export const BANK_STATUS = {
  EXPIRED: "EXPIRED",
  INCORRECT_URL: "INCORRECT_URL",
  ALREADY_COMPLETED: "ALREADY_COMPLETED",
};

export const USER_NOT_FOUND = "User not found";
export const OTP_SENT = "otp sent";
export const OTP_VERIFIED = "otp verified";

export const PAYMENT = {
  PENDING: "PENDING",
  PROCESSING: "PROCESSING",
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
};
